.news-detail {
  .secondary-slider,
  .secondary-slider_empty {
    height: 282px;
  }

  .news-item-related {
    .secondary-slider {
      height: 470px;
    }
    .secondary-slider_empty {
      height: auto;
    }
  }

  .list-item_text {
    font-size: 14px;
    font-weight: 600;
    color: #202124;
  }
}

.news-item-title {
  font-size: 38px;
  font-weight: 600;
  line-height: 1.26;
  color: #333333;

  //&.print {
  //  font-size: 24px;
  //}
}

.news-separator {
  border-bottom: 1px solid grey;
}

.news-item-wrapper {
  padding-right: 20px;
  width: 100%;
}
.about-content {
  p {
    overflow: inherit;
  }

  figcaption {
    text-align: center;
    margin-top: 15px;
  }
}

.from-editor {
  figcaption {
    text-align: center;
    margin-top: 15px;
  }
  p {
    overflow: inherit;
  }
}
.news-item-description {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #666;
  &.from-editor {
    p {
      overflow: inherit;
    }
    figcaption {
      text-align: center;
      margin-top: 15px;
    }
    ul {
      list-style: disc;
      padding-left: 17px;
    }
    ol {
      list-style: decimal;
      padding-left: 17px;
    }
  }
  > img {
    max-width: 100%;
    width: 100%;
    padding: 10px 0;
  }
   a {
    color: #df6d17;
    &:hover {
      text-decoration: underline;
      strong {
        text-decoration: underline;
      }
    }
  }
  h2 {
    font-size: 22px;
    line-height: 1.5;
  }

  h3 {
    font-size: 18px;
    line-height: 1.5;
  }
  h4 {
    font-size: 16px;
    line-height: 1.5;
  }
}

.placeholder {
  width: 100%;
  min-height: 150px;
  margin: 10px 0;
}

.news-item-text {
  line-height: 30px;
  font-size: 16px;
  margin: 10px 0;
  color: #666;
}

.news-item-image {
  position: relative;
  width: 100%;
  img {
    max-width: 100%;
    width: 100%;
  }
}

.news-item-image_description {
  position: absolute;
  background: rgba(255, 255, 255, .8);
  padding: 15px;
  width: 100%;
  bottom: 0;
}

.bottom-date-wrapper {
  margin-left: auto;
  align-items: flex-end;
  width: 120px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 10px 0 5px;
  .share-news-wrapper:after {
    content: '';
    //background: rgba(68, 67, 66, .8);
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
  }
  .news-date {
    text-align: right;
  }
}

.tags {
  display: flex;
  margin-bottom: 20px;
}

.news-section {
  width: 100%;
}

.news-item {
  max-width: 205px;
  width: 100%;
}

.list-wrapper {
  width: 100%;
  padding-right: 20px;
}

.news-date > * {
  font-size: 12px;
}

.list-item_wr {
  display: flex;
  box-shadow: 0 10px 25px 0 rgba(0,0,0,.1);
  flex-direction: column;
  position: relative;
  max-width: 205px;
  height: 205px;
  justify-content: space-between;
  &:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, .3);
    cursor: pointer;
  }
}

.list-title {
  font-size: 22px;
  margin: 25px 0;
  font-weight: 600;
}

.secondary-slider_empty > * {
  margin-bottom: 20px;
  //max-width: 24%;
  padding: 0 .5%;
  width: 24%;
}

.secondary-slider {
  position: relative;
  width: 100%;
}

.list-item_text {
  background: #fff;
  position: relative;
  padding: 10px;
  z-index: 10;
}

.secondary-slider:before {
  content: '';
  background: #fff;
  position: absolute;
  height: 100%;
  z-index: 10;
  width: 8px;
  left: 0;
  top: 0;
}

.secondary-slider:after {
  content: '';
  background: #fff;
  position: absolute;
  height: 100%;
  z-index: 10;
  width: 7px;
  right: 0;
  top: 0;
}

.attached-files {
  background-color: rgba(255, 255, 255, .6);
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
}

.attached-files li {
  font-size: 12px;
  padding: 5px 10px;
}

.list-item_image {
  background: #ddd;
  position: relative;
  height: 150px;
}

.share-news-wrapper {
  height: 55px;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 0 20px;
}

.print {
  background: url("../svg/print.svg") 50% no-repeat;
  background-size: contain;
  margin-right: 35px;
  height: 25px;
  width: 25px;
  &:hover {
    cursor: pointer;
  }
}

.share {
  background: url("../svg/share.svg") 50% no-repeat;
  background-size: contain;
  margin-left: 15px;
  height: 25px;
  width: 25px;
  &:hover {
    cursor: pointer;
  }
}

.small-card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  overflow: hidden;
  height: 115px;
  img {
    max-width: 100%;
    width: 100%;
  }
}

.secondary-slider:after {
  display: none;
}

.news-info-share_wrapper {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .news-item-title_wrapper {
    flex-direction: column;
  }

  .news-item-title_wrapper.page {
    flex-direction: row;
  }

  .news-item-title {
    margin-bottom: 25px;
    margin-top: 15px;
    font-size: 18px;
  }

  .secondary-slider {
    margin-left: 0;
    padding-left: 0;
  }

  .news-info-share_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .share-news-wrapper {
    padding: 0;
  }

  .news-item-description {
    font-size: 14px;
    line-height: 22px;
  }

  .share-news-wrapper {
    padding: 0;
    &:after {
      display: none;
    }
  }

  .secondary-slider:after,
  .secondary-slider:before {
    display: none;
  }

  .bottom-date-wrapper {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .list-item_wr {
    max-width: 100%;
  }

  .list-item_image {
    overflow: hidden;
  }

  .slick-slide img {
    max-width: 100%;
    //width: 100%;
  }

  .modal-content .slick-arrow.slick-next {
    right: 10px;
  }

  .modal-content .slick-arrow.slick-prev {
    left: 10px;
  }

  .list-item_text {
    padding: 10px 10px 40px 10px;
    font-size: 20px;
    color: #202124;
  }

  .small-card {
    width: 100%;
    //max-width: 100%;
    margin: 0 auto;
  }

  .small-news-description {
    overflow: hidden;
    margin: 15px;
    height: 65px;
  }

  .small-news-title {
    height: 45px;
    overflow: hidden;
  }

  .news-item-wrapper {
    padding-right: 0;
  }

  .news-detail {
    .main-content-block {
      padding: 10px 0 50px;
    }
  }

  .secondary-slider_empty > * {
    width: 50%;
  }
}

.share-news-wrapper.page-item {
  @media screen and (max-width: 768px) {
    margin-left: auto;
  }
}

//.custom-print .news-item-title h3 {
//  font-size: 200px;
//}
//@media print and (monochrome) {
//@media print {
//  .PrintSection h3.news-item-title {
//    font-size: 200px;
//  }
//}
