@mixin spec-scroll {
  /* width*/
  ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15);
  }
  scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #444342;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #858585;
  }
}

.books-accordion-wrapper {
  position: relative;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  .accordion-head {
    padding: 5px;
    flex-wrap: wrap;
    .search-block {
      max-width: 335px;
    }

    .search-block input {
      height: 35px;
      min-width: 200px;
    }

    .search-block button {
      background-size: 70%;
      height: 35px;
      width: 35px;
    }
  }

  .accordion-body {
    display: flex;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,.15);
    justify-content: space-between;
    padding: 25px 15px 15px 15px;
  }

}

.accordion-button-books {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  margin-left: 16px;
  &:hover {
    cursor: pointer;
  }
}

.books-count-image {
  max-width: 60px;
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  align-items: center;
  white-space: nowrap;

  span {
    height: 20px;
    font-size: 14px;
    line-height: 1.43;
    color: #444342;
    margin-left: 4px;
  }
}

.books-filter-wrapper {
  max-width: 190px;
  @include spec-scroll;
}

.books-filter-tags-wrapper {
  @include spec-scroll;
}

.books-type-wrapper {
  max-width: 190px;

  .books-filter-list {
    -webkit-flex-wrap: wrap;
    max-height: 160px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.books-filter-list {
  -webkit-flex-wrap: wrap;
  max-height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.books-filter-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 174px;
  padding: 10px 10px 10px 0;

  label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + .checkbox-facade {
    background: #df6d17;
    background-image: url(../svg/CheckBox.svg);
  }
}

.books-filter-row {
  display: flex;
  justify-content: space-between;
}