.App-footer {
  background: url('../images/footer-back.jpg') 50% no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.footer-static-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer .telegram-button {
  position: relative;
  background: transparent;
  &:hover {
    cursor: pointer;
    .telegram-list-footer {
      display: block;
    }
  }
  &:after {
    display: none;
  }

  .telegram-list-footer {
    display: none;
    position: absolute;
    top: auto;
    bottom: 100%;
    width: max-content;
    max-width: 300px;
    background-color: #302e33;
    box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.14);

    li {
      border-bottom: 1px solid #7d7d7d;
    }

    @media all and (max-width: 768px) {
      max-width: 200px;
    }
  }
}

.footer-centered {
  height: auto;
  padding: 5px 5px 30px 5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(255,255,255,.12);
  margin-top: 40px;
}

.footer-centered-confidential {
  display: flex;
  align-items: center;
  border-top: 1px solid #3f4245;
  padding: 15px 10px;
  margin-top: 20px;
  height: 60px;
  width: 100%;
  color: #fff;
}

.footer-links {
  margin-top: -25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: #df6d17;
      text-decoration: underline;
    }
  }
}

.footer-links a.link-header.no-underline:hover {
  text-decoration: none;
}

.footer-link-category {
  width: calc(25% - 10px);
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #df6d17;
  margin-top: 3px;
}

.footer-link-block-header {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  font-size: 13px;
}

.link-header {
  font-size: 16px;
  margin-left: 3px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.footer-sublinks {
  margin-left: 12px;
  margin-bottom: 10px;
  color: white;
  font-size: 14px;
  line-height: 1.3;
}

#footer-copyright {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  color: gray;
}

.confidential-condition-block a {
  font-size: 12px;
  float: right;
  text-decoration: none;
  color: #fff;
  margin-left: 20px;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
    color: #df6d17;
  }
}

#footer-contacts p {
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  padding-left: 58px;
}

.footer-social-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

.footer-logo-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.footer-centered > .footer-logo-block {
  display: none;
}

@media all and (max-width: 1080px) {
  .footer-logo-block {
    width: 100%;
    padding-bottom: 10px;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  footer .footer-static-block {
    flex-direction: row;
  }

  .footer-centered {
    border-bottom: .5px solid #5e5e5e;
    padding: 0;
  }

  .footer-link-category {
    width: calc(50% - 10px);
    //margin: 10px 0;
  }

  .footer-social-block {
    margin: 0;
  }

  .footer-static-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: .5px solid #5e5e5e;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}

.footer-centered-confidential.outside-confidential {
  display: none;
}

@media all and (max-width: 1080px) {
  .footer-link-block-header {
    padding: 10px 0;
    //margin-top: 0;
  }

  .footer-centered-confidential.outside-confidential {
    display: flex;
  }

  .footer-social-block {
    margin: 0 auto;
  }

  .footer-centered {
    #footer-contacts,
    .footer-centered-confidential,
    .footer-static-block .footer-logo-block {
      display: none;
    }
  }
}

@media all and (max-width: 768px) {
  .footer-link-block-header {
    padding: 10px 0;
    margin-top: 0;
  }

  .footer-sublinks {
    display: none;
  }

  .footer-no-links {
    display: none;
  }

  .footer-centered > .footer-logo-block {
    display: flex;
  }

  .footer-links {
    flex-direction: column;
    margin-top: 0;
  }

  .footer-link-category {
    width: 100%;
    //margin: 10px 0;
  }

  .link-header {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .footer-social-block {
    margin: 0 auto;
  }
}
