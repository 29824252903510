@mixin telephone-styled {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342;
}

.image-style-align-left {
  float: left;
  padding: 20px 20px 20px 0;
  width: 35%;
}

.image-style-align-right {
  float: right;
  padding: 20px 0 20px 20px;
  width: 35%;
}

.image-style-align-center {
  margin: 0 auto;
  padding: 20px 20px 20px 0;
  width: 35%;
}

.about-big-name {
  font-size: 48px;
  font-weight: 600;
  color: #202124;
  text-align: center;
  margin-bottom: 30px;
}

.about-mediate-name {
  @extend .about-big-name;
  width: 100%;
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 0;
}

.about-indent {
  margin-top: 25px;
}

.about-content {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: #444342;
}

.side-links {
  position: fixed;
  left: 1px;
  top: 215px;
  z-index: 1;
}

.side-link-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 20px 20px 0;
  margin-bottom: 20px;
  background: #fff;
  height: 44px;
  width: 125px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    transition: width .3s;
    width: 135px;
  }

  &:hover > div {
    color: #444342;
  }
}

.side-link-item-text {
  width: 108px;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  color: #acacac;
}

.about-image {
  max-width: 260px;
  max-height: 255px;
}

.itend-left {
  margin-left: 20px;
}

.about-wrapper {
  padding-right: 25px;
}

.boss-block {
  width: 100%;
  .boss-structure {
    box-shadow: 0 12px 24px 0 rgba(0,0,0,.15);
    background-color: #ffffff;
    padding: 25px;
  }
  .arrow-right {
    margin-top: 0;
  }
}

.boss-block-header {
  height: 27px;
  font-size: 22px;
  font-weight: 600;
  color: #202124;
  margin-top: 25px;
  margin-bottom: 25px;
}

.director-row {
  display: flex;
  justify-content: center;
}

.sub-director-row {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  .secondary-slider {
    height: 350px;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 440px){
      height: 390px;
    }

    .slick-slide img {
      height: 70px;
      object-fit: cover;
    }
  }

  .secondary-slider .slick-slide > div {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
  }
}

.structure-item {
  max-width: 195px;
  width: 93%;
  height: 290px;
  box-shadow: 0 10px 25px 0 rgba(0,0,0,.1);
  background: #fff;
  padding: 15px 11px;
}

.boss_face {
  text-align: center;
  img {
    display: initial;
    width: 70px;
    height: auto;
    border-radius: 50%;
    max-width: 100%;
  }
}

.boss-fio {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #444342;
  margin-top: 15px;
  text-align: center;
  overflow: hidden;
  max-height: 60px;

  a:hover {
    text-decoration: underline;
  }
  //&:hover {
  //  text-decoration: underline;
  //  cursor: pointer;
  //}
}

.boss-position-row {
  font-size: 12px;
  line-height: 1.33;
  color: #444342;
  margin-top: 12px;
  display: flex;
  align-items: baseline;
  span {
    margin-left: 5px;
  }
}

.sub-boss-position-row {
  @extend .boss-position-row;
  margin-left: 15px;
  max-height: 88px;
  overflow: hidden;
}

.bottom-boss-position-row {
  font-size: 12px;
  line-height: 1.33;
  color: #444342;
  align-items: flex-start;
  span {
    margin-left: 5px;
    display: flex;
    align-items: flex-start;
    max-width: 180px;
  }
}

.organizational_structure {
  width: 100%;
  min-height: 620px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  display: flex;
}

.left-organizational-column {
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.organizational-pyramid {
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px;
  flex-grow: 1;

  p, span {
    padding: 0 10px;
  }

  div:last-child {
    background-color: #f8ca7f;
    text-decoration: underline;
  }

  .pyramid-director-level {
    width: 100%;
    height: 45px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    &:hover {
      border: 1px solid #df6d17;
      cursor: pointer;
    }
  }

  .pyramid-level-1 {
    width: 90%;
    min-height: 60px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
      border: 1px solid #df6d17;
      cursor: pointer;
    }
  }

  .pyramid-level-2 {
    width: 80%;
    @extend .pyramid-level-1;
  }

  .pyramid-level-3 {
    width: 70%;
    @extend .pyramid-level-1;
  }

  span {
    font-size: 12px;
    line-height: 1.33;
    color: #444342;
    margin: 0;
  }
}

.organizational-contacts {
  border-top: 1px solid #dbdbdb;
  text-align: left;
  padding: 20px 0 0 15px ;
}

.organization-address {
  background-color: #ffffff;
  max-height: 80px;
  span {
    height: 27px;
    font-size: 22px;
    font-weight: 600;
    color: #202124;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.orgstr-addrr-block {
  padding-top: 21px;
  padding-left: 15px;
  display: block;

  border-top: 1px solid #dbdbdb;
}

.orgstr-addrr-header {
  span {
    height: 27px;
    font-size: 22px;
    font-weight: 600;
    color: #202124;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.orgstr-addrr-addrr-photo {
  display: flex;
  margin-bottom: 20px;
  //max-width: 120px;
}

.orgstr-right-indent {
  margin-right: 10px;
}

.orgstr-addrr-point {
  display: flex;

  .office-address-button {
    //width: 250px;
    //border: 1px solid #555;
    background-color: #df6d17;
    color: #fff;
    border-radius: 10px;
    padding: 2px 15px;
    margin-top: 5px;
  }
}

.mini-img {
  line-height: 1.88;
  height: 25px;
  width: 25px;
}

.orgstr-addrr-contacts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .tel-numbers {
    @include telephone-styled;
  }
}

.orgstr-addrr-point-left {
  display: flex;
  margin-bottom: 6px;
  flex-basis: calc(50% - 10px);
}

.orgstr-icon-photo {
  width: 128px;
  //height: 112px;
  overflow: hidden;
  margin-right: 20px;
  //margin-top: 5px;
}

.orgstr-addrr-office-img {
  width: 100%;
  //height: 100%;
  //object-fit: contain;
  //overflow: hidden;
}

.bottom-position-item {
  display: -webkit-flex;
  display: flex;
  margin: 10px 0 20px 14px;
  //flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  align-items: center;
  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
    max-width: 100%;
    margin-right: 5px;
  }
  .bottom-boss-fio {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #444342;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .bottom-name-position {
    padding: 5px;
    //max-width: 365px;
  }
}

.organizational-column-item {
  width: 200px;
  height: 78px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 15px;
    span {
      font-size: 12px;
      line-height: 1.33;
      color: #444342;
      text-align: left;
      width: 85%;
    }
  &:hover {
    border: solid 1px #df6d17;
    cursor: pointer;
  }
}

.right-organizational-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  flex: auto;
  width: 200px;
  margin: 25px 10px;
}

.mobile-organizational-column {
  display: none;
  border-top: 1px solid #dbdbdb;
  padding-top: 20px;
  padding-bottom: 20px;

.organizational-column-item {
  width: 45%;
  height: auto;
  }
}

@media all and (max-width: 1410px) {
  .side-links {
    display: none;
  }
}

@media all and (max-width: 1226px) {
  .side-news-block {
    display: none;
  }
  .no-gutter {
    display: block;
  }
}

@media all and (max-width: 1225px) {
  .news-content-block {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .right-organizational-column {
    display: none;
  }

  .mobile-organizational-column {
    display: block;
  }
}

@media all and (max-width: 440px) {
  .about-mediate-name {
    font-size: 30px;
  }
  .about-history-block {
    //display: none;
  }
  .orgstr-addrr-addrr-photo {
    display: block;
  }
  .orgstr-addrr-contacts {
    display: block;
  }
  .structure-item {
    width: 170px;
  }
  .director-row {
    .structure-item {
      width: 170px;
    }
  }
  .boss-fio {
    font-size: 13px;
  }
  .boss-position-row {
    font-size: 10px;
    margin-top: 10px;
    span {
      font-size: 9px;
    }
  }
  .bottom-boss-position-row {
    span {
      font-size: 10px;
    }
  }
  .breadcrumb-wrapper {
    display: none;
  }
  .boss-block-header {
    font-size: 16px;
    font-weight: 600;
    color: #202124;
  }
}

