.services-item {
  //display: flex;
  display: block;
  flex-direction: column;
  background: #fff;
  height: 220px;
  //align-items: center;
  //justify-content: center;
  text-align: center;
  padding-top: 25px;
  will-change: box-shadow;
  &:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, .3);
    transition: all .3s;
    cursor: pointer;
  }
}

.services {
  display: flex;
  justify-content: space-between;
}

.services-image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.services-image {
  max-width: 100px;
  width: 100%;
}

.services-text {
  height: 55px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-align: center;
  color: #5e5e5e;
  margin-top: 28px;
  padding: 0 10px;
  overflow: hidden;
}
