//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('assets/fonts/Montserrat-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat-SemiBold.woff') format('woff'), /* Pretty Modern Browsers */
  url('assets/fonts/Montserrat-SemiBold.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat-Italic.woff') format('woff'), /* Pretty Modern Browsers */
  url('assets/fonts/Montserrat-Italic.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat-SemiBoldItalic.woff') format('woff'), /* Pretty Modern Browsers */
  url('assets/fonts/Montserrat-SemiBoldItalic.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: italic;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1 0 auto;
}

.App-header {
  box-shadow: 0 0 5px 0 rgba(0,0,0,.10);
}

.App-footer {
  flex-shrink: 0;
}

.logo-container {
  width: auto;
  height: 60px;
  img {
    max-width: 100%;
    width: 100%;
  }
}

.App-service {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.line-breadcrumb {
  .home-breadcrumb {
    i {
      color: #df6d17;
    }
  }
  .active {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #df6d17;
  }
}

.modal-wrapper {
  display: flex;
  background: rgba(32,33,36,.8);
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1001;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.modal-content {
  position: fixed;
  max-width: 840px;
  margin: 0 auto;
  width: 80%;
  right: 0;
  left: 0;
}

hr {
  display: block;
  margin: 1em 0;
  height: 1px;
  padding: 0;
  border: 0;
  border-top: 1px solid rgba(68,67,66,.8);
}

.fa, .fab {
  font-size: 20px;
}

.no-active {
  pointer-events: none;
}

.iframe-container {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
  left: 0;
  top: 0;
}

.line-breadcrumb a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.off-pointer {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

.off-pointer *:hover {
  cursor: default !important;
}
