$arrow-width: 50px;

.slider-media {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.25;
    padding: 15px 0;
    color: #fff;
  }

  .slick-arrow {
    background: url('../svg/arrow.svg') 50% no-repeat;
    background-size: 8px;
    position: absolute;
    font-size: 0;
    height: 50px;
    width: $arrow-width;
    top: calc(50% - 25px);
    z-index: 10;
    &:hover {
      background: url('../svg/arrow-hover.svg') 50% no-repeat;
      background-size: 8px;
    }
  }

  .slick-prev {
    left: -80px;
    &:hover {
      &:after {
        content: '';
        border-radius: 0 50px 50px 0;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        border-top: 1px solid #fff;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 50px 0 0 50px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
      }
    }
  }

  .slick-next {
    transform: rotate(180deg);
    right: -80px;
    &:hover {
      &:after {
        content: '';
        border-radius: 0 50px 50px 0;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        border-top: 1px solid #fff;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 50px 0 0 50px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
        left: 1px;
      }
    }
  }

  .slick-prev:after,
  .slick-next:after {
    content: '';
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    top: 1px;
    right: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    justify-content: center;
    bottom: -70px;
    right: 0;
    left: 0;

    li {
      position: relative;
      margin: 5px;
      font-size: 0;
      height: 15px;
      width: 15px;
      &:hover {
        border: 1px solid #df6d17;
        border-radius: 50%;
        cursor: pointer;
      }
      &:after {
        content: '';
        background: #fff;
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }

      button {
        position: relative;
        z-index: 10;
        height: 100%;
        width: 100%;
      }
    }

    .slick-active:after {
      background: #df6d17;
    }
  }
}

.slider-media .slick-slide img {
  max-width: 100%;
  max-height: 65vh;
  display: inline;
  //width: 100%;
}

.slider-media_top-item {
  position: relative;
  text-align: center;
  min-height: 100px;
  background: rgba(255, 255, 255, .3);
  h5 {
    background: rgba(255, 255, 255, .7);
    position: absolute;
    text-align: left;
    padding: 15px;
    width: 100%;
    bottom: 0;
  }
}

.slider-media_bot-item {
  text-align: center;
  min-height: 50px;
  h5 {
    text-align: left;
    padding: 7px 15px 15px 15px;
    color: #fff;
  }
}

.slider-media_bot {
  .slick-slider {
    overflow: hidden;
  }

  .slick-slide > div {
    width: calc(100% - 12.5px);
    margin: 0 0 15px;
  }

  .slick-arrow {
    bottom: 0;
    top: auto;
  }

  .slick-prev {
    left: 25%;
  }

  .slick-next {
    right: 25%;
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-list {
    padding-top: 10px;
    padding-left: 20px;
    margin-left: -20px;
    overflow: hidden;
    height: 230px;
    //height: 62vh;
  }
}

.slider-media.slider-media-files {
  .slick-arrow {
    bottom: -55px;
    top: auto;
  }

  .slick-prev {
    left: 25%;
  }

  .slick-next {
    right: 25%;
  }

  .slick-dots {
    bottom: -60px;
  }

  h5 {
    text-align: left;
    padding: 15px 0;
    color: #fff;
  }

  .slider-files-item {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .slider-files-item li {
    width: calc(25% - 12.5px);
  }

  .slider-files-sub img {
    border: 2px solid transparent;
  }

  .slider-files-sub.selected img {
    border: 2px solid #df6d17;
  }

  .download-button {
    position: absolute;
    border-radius: 50px;
    background: #df6d17;
    padding: 10px;
    font-size: 24px;
    width: 100px;
    color: #fff;
    bottom: -55px;
  }
}

@media all and (max-width: 576px) {
  .chart-item {
    overflow: auto;
  }
  .slider-media_bot {
    display: none;
  }
}

@media all and (max-width: 320px) {
  .secondary-slider .slick-next {
    right: calc(50% - 128px);
  }

  .secondary-slider .slick-prev {
    left: calc(50% - 128px);
  }
}
