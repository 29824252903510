@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/v4-shims.css);

$lang-width: 75px;
$social-color: #acacac;

.social-icon {
  color: $social-color;
}

footer .social-icons {
  margin: 0;
}

.social-icons {
  margin: 0 $lang-width 0 0;
  display: flex;
  li {
    height: 35px;
    width: 35px;
    position: relative;
    display: flex;
    align-items: center;
    &:first-child {
      &:after {
        content: '';
        color: $social-color;
        position: absolute;
        height: 75%;
        width: 1px;
        left: 0;
      }
    }

    a {
      text-decoration: none;
      justify-content: center;
      align-items: center;
      display: flex;
      color: $social-color;
      height: 100%;
      width: 100%;
    }
  }
}

.fa-telegram-plane {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #0088cc;
  }
}

.fa-facebook {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #3d5b99;
  }
}

.fa-twitter {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #00aced;
  }
}

.fa-instagram {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #eb8231;
  }
}

.fa-youtube {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #df6d17;
  }
}

.fa-linkedin {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #0073a4;
  }
}

.fa-google-plus {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  &:hover {
    color: #e25714;
  }
}

.social-vertical {
  height: 20px;
}