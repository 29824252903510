.big-news-image {
  //background: #ddd;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 248px;
  width: 100%;
  img {
    width: 100%;
  }
}

.truncate-wrapper,
.big-news-title > a {
  display: block;
}

.big-news-title {
  //background-image: linear-gradient(to bottom, rgba(255, 255, 255, .6), #fff);
  padding: 10px 15px 10px 15px;
  //position: absolute;
  color: #202124;
  width: 100%;
  overflow: hidden;
  //bottom: 0;
  //&:hover * {
  //  cursor: pointer;
  //  //text-decoration: underline;
  //}
  h3 {
    max-height: 77px;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    //max-height: 402px;
  }
}

.big-card > a {
  //background: #888;
  display: block;
  height: 394px;
  overflow: hidden;
  width: 100%;
}

.big-card {
  //position: relative;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .05);
  background: #fff;
  max-width: 432px;
  height: 438px;
  &:hover {
    box-shadow: 0 15px 25px 0 rgba(223, 109, 23, .25);
    transition: all .3s;
    cursor: pointer;
  }
}

.big-news-description {
  line-height: 1.43;
  margin: 8px 15px 0 15px;
  font-size: 14px;
  color: #444342;
  max-height: 98px;
  overflow: hidden;

  p {
    overflow: hidden;
  }
}

.big-news-tag-block {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 10px 10px 10px;
  height: 25px;

  span {
    padding-bottom: 5px;
    font-size: 13px;
  }
}

.tag-item {
  border: 1px solid transparent;
  background: #e9e9e9;
  border-radius: 9px;
  //align-items: center;
  margin: 0 5px 5px 0;
  padding: 1px 10px;
  font-size: 12px;
  //display: flex;
  height: 18px;
  color: #000;
  line-height: 14px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    //border: 1px solid #df6d17;
    //background: #fff;
    //cursor: pointer;
  }
}
