.news-item {
  max-width: 208px;
  min-height: 380px;
  margin: 8px 0;
  width: 100%;
  display: flex;
}

.empty-text {
  font-size: 16px;
  padding: 15px;
}

.news-double-item {
  max-width: 430px;
  max-height: 438px;
  margin: 9px 0;
  width: 100%;
}

.side-news-block {
  padding-top: 10px;
  width: 100%;
  flex: 1;

  .side-news-wrapper {
    width: 100%;
  }
}

.side-news-block.fixed {
  .side-news-wrapper {
    position: fixed;
    top: 30px;
  }
}

.side-news-block.bottom {
  position: absolute;
  max-width: 208px;
  bottom: 30px;
  right: 5px;
  .side-news-wrapper {
    display: flex;
    flex-direction: column;
    position: initial;
    flex-wrap: wrap;
  }
}

.side-text_wr {
  background: rgba(305,305,305,.5);
  padding: 8px 15px 10px 15px;
  transition: all .3s;
  position: absolute;
  font-weight: 600;
  line-height: 1.4;
  font-size: 14px;
  color: #202124;
  bottom: -100%;
  height: 72px;
  width: 100%;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.side-news-item {
  display: flex;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.15), 0 15px 25px 0 rgba(0,0,0,.05);
  justify-content: center;
  align-items: center;
  background: #dadada;
  min-height: 208px;
  max-width: 208px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  &:hover {
    box-shadow: 0 15px 25px 0 rgba(223,109,23,.25);
    transition: all .3s;
    cursor: pointer;
    .side-text_wr {
      bottom: 0;
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.side-news-image {
  background: #ddd;
  position: absolute;
  width: 100%;
}


.news-content-block {
  display: flex;
  flex-wrap: wrap;
  width: 81%;
  > .last-news-item, .row {
    width: 100%;
    padding: 0;
  }
}

.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
  padding-right: 0;
  padding-left: 0;
}

.main-content-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0 50px;
  > div:last-child {
    width: 20%;
  }
}

@media screen and (max-width: 1226px) {
  .main-content-block > div:last-child {
    display: none;
  }
}

.news-time-block {
  font-size: 12px;
  color: #444342;
  margin-left: 15px;
  span {
    font-size: 12px;
    font-weight: 900;
    margin-right: 10px;
  }
}

.tags-share-wrapper {
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
}

.share-wrapper {
  position: relative;
  height: 30px;
  width: 30px;
}

.share-list {
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  will-change: box-shadow;
  position: absolute;
  font-size: 18px;
  color: #acacac;
  height: 30px;
  width: 30px;
  margin: 0;
  bottom: 0;
  left: 0;
}

.share-list-down {
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  will-change: box-shadow;
  position: absolute;
  font-size: 18px;
  color: #acacac;
  height: 30px;
  width: 30px;
  margin: 0;
  right: 0;
  top: 7px;

  @media all and (max-width: 768px) {
    top: 12px;
  }

  &:hover {
    cursor: pointer;
  }
  .share-item {
    bottom: initial;
    left: initial;
    right: 0;
    top: 0;
  }
}

.share-list.opened,
.share-list-down.opened {
  transition: box-shadow .3s ease-in-out .15s;
  box-shadow: 0 0 30px 0 rgba(0,0,0,.14);
}

.share-item {
  display: flex;
  transition: transform .3s ease-in-out;
  will-change: transform;
  transform: translateY(0);
  justify-content: center;
  align-items: center;
  background: #fff;
  position: absolute;
  height: 30px;
  width: 100%;
  bottom: 0;
  left: 0;
}

.news-bottom {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //position: relative;
  //top: -50px;
}

.big-name {
  width: 771px;
  height: 58px;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 35px;
  color: #202124;
}

.part-marg, .marg {
  min-height: 300px;
}

.cutted-hr {
  margin-left: 0;
  width: 98%;
}

.news-wrapper {
  display: flex;
  flex-wrap: wrap;
  .news-page-item {
    padding: 0;
  }
}

.accordion-wrapper {
  .accordion-button {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  img {
    transition: transform .3s;
    transform: rotate(-90deg);
    margin-left: 15px;
  }
}

.accordion-wrapper.opened {
  img {
    transform: rotate(90deg);
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
  margin: 10px 0;

  .accordion-wrapper {
    .accordion-head {
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0);
      background: #f6f6f6;
      border-radius: 5px;
      padding: 0 15px;
    }

    .accordion-body {
      top: calc(100% + 1px);
      padding: 10px 0;
      width: auto;

      ul {
        width: 100%;
        margin: 0;
        li {
          padding: 10px 15px;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
            color: #df6d17;
          }
        }
      }
    }
  }

  .accordion-wrapper.opened {
    .accordion-head {
      border-radius: 5px 5px 0 0;
      background: #fff;
    }

    .accordion-body {
      border-radius: 0 0 5px 5px;
      min-width: 191px;
    }
  }
}

.pagination-list {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 5px;

    li {
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      margin: 2px;

      @media all and (max-width: 440px) {
        height: 25px;
        width: 25px;
        font-size: 12px;
      }

      &.active {
        background: #df6d17;
        cursor: default !important;
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        border: 1px solid #df6d17;
      }
    }
  }

  .slick-arrow {
    background: url('../svg/arrow-grey.svg') 50% no-repeat;
    background-size: 5px;
    position: relative;
    font-size: 0;
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    z-index: 10;
    &:hover {
      background: url('../svg/arrow-hover.svg') 50% no-repeat;
      background-size: 5px;
      cursor: pointer;
    }
  }

  .slick-prev {
    &:hover {
      &:after {
        content: '';
        border-radius: 0 30px 30px 0;
        border-bottom: 1px solid #5e5e5e;
        border-right: 1px solid #5e5e5e;
        border-top: 1px solid #5e5e5e;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 30px 0 0 30px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
      }
    }
  }

  .slick-next {
    transform: rotate(180deg);
    &:hover {
      &:after {
        content: '';
        border-radius: 0 30px 30px 0;
        border-bottom: 1px solid #5e5e5e;
        border-right: 1px solid #5e5e5e;
        border-top: 1px solid #5e5e5e;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 30px 0 0 30px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
        left: 1px;
      }
    }
  }

  .slick-prev:after,
  .slick-next:after {
    content: '';
    border: 1px solid #5e5e5e;
    border-radius: 50%;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    right: 0;
    top: 1px;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.selected-filters_wrapper {
  display: flex;
  border-top: 1px solid #ededed;
  box-shadow: 0 12px 24px 0 rgba(0,0,0,.05);
  align-items: center;
  margin: 0 19px 10px 0;
}

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;
  margin: 10px 0;
}

.selected-filters_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #444342;
  border-radius: 10px;
  margin: 0 5px 5px 0;
  padding: 3px 8px;
  font-size: 14px;
}

.filter-cross {
  border: 1px solid #444342;
  border-radius: 50%;
  margin-left: 5px;
  height: 14px;
  width: 14px;
  &:hover {
    border: 1px solid #f0001d;
    cursor: pointer;
    &:after,
    &:before {
      background: #f0001d;
    }
  }
}

.filter-cross_big {
  border: 1px solid #444342;
  margin: 0 5px 5px 0;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  &:hover {
    border: 1px solid #f0001d;
    cursor: pointer;
    &:after,
    &:before {
      background: #f0001d;
    }
  }
}

.filter-cross,
.filter-cross_big {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-cross:before,
.filter-cross_big:before {
  content: '';
  transform: rotate(-45deg);
  position: absolute;
  background: #000;
  height: 1px;
  width: 12px;
}

.filter-cross:after,
.filter-cross_big:after {
  content: '';
  transform: rotate(45deg);
  position: absolute;
  background: #000;
  height: 1px;
  width: 12px;
}

.selected-filters {
  .filter-cross:after,
  .filter-cross:before {
    width: 8px;
  }
}

.accordion-wrapper {
  .sort-text_wr {
    display: flex;
    align-items: center;
    font-size: 14px;
    .sort-image {
      transform: none;
      margin: 0 10px 0 0;
    }
  }
}

.pagination-search {
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;
}

.search-item_wrapper {
  box-shadow: 0 10px 24px 0 rgba(0,0,0,.1);
  background: #fff;
}

.search-item_title {
  text-decoration: underline;
  font-weight: 600;
  font-size: 22px;
  color: #202124;
  &:hover {
    cursor: pointer;
    color: #df6d17;
  }
}

.search-item_text {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: .5px;
  color: #444342;
}

.search-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 20px 15px 0;
}

.search-item_wrapper {
  margin-bottom: 15px;
  padding: 15px;
}

.news-item-title_wrapper {
  display: flex;
  justify-content: space-between;
}

.search-filters_wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  width: 100%;
}

.search-filters_list {
  display: flex;
  flex-wrap: wrap;
}

.filters-search_title {
  font-size: 14px;
  font-weight: 500;
  color: #444342;
}

.accordion-button p {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #444342;
}

.search-filters_item {
  font-size: 14px;
  font-weight: 500;
  color: #444342;
  margin-top: 10px;
  max-width: 25%;
  width: 25%;
  label {
    display: flex;
    padding-right: 10px;
  }
}

@media screen and (max-width: 1080px) {
  .news-double-item {
    //overflow: hidden;
    //width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .news-double-item {
    border: 1px solid rgb(245, 245, 245);
  }

  .news-page {
    .accordion-wrapper {
      padding-right: 0;
    }

    .big-card, .big-news-image {
      //height: auto;
    }

    .selected-filters_wrapper {
      margin: 0 0 10px 0;
      border: none;
    }

    .accordion-head {
      flex-direction: column;
    }

    .accordion-wrapper .accordion-head {
      box-shadow: 0 12px 24px 0 rgba(0,0,0,.15);
      margin-bottom: 15px;
    }

    .pagination-wrapper {
      padding: 0;
      flex-direction: column;
    }

    .accordion-wrapper {
      width: 100%;
    }

    .page-title {
      font-size: 28px;
    }

    .main-content-block {
      padding: 20px 0 50px;
    }

    .big-news-description {
      //margin: 20px 10px;
      //font-size: 14px;
      //line-height: 16px;

    }

    .big-news-title  {
      //padding: 10px 10px 0 10px;
      &:hover * {
        //cursor: pointer;
        //text-decoration: underline;
      }
    }

    .big-news-title * {
      //font-size: 14px;
    }

    .big-news-tag-block {
      display: none;
    }

    .accordion-wrapper.opened {
      .accordion-body {
        border-radius: 0 0 5px 5px;
        min-width: 190px;
        width: 100%;
      }
    }

    .accordion-wrapper {
      .accordion-body {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .accordion-head {
        margin: 0;
      }
    }

    .pagination-list {
      margin-top: 20px;
    }

    .datepicker-wrapper {
      border-right: none;
    }

    .filters-item {
      width: 50%;
    }

    .news-double-item, .big-card {
      max-width: 100%;
    }
  }

  .news-bottom,
  .news-time-block {
    //display: none;
  }
}
