$lang-width: 75px;

.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-right_top {
  position: relative;
  display: flex;
}

.telegram-wrapper {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.telegram-button:after {
  display: block;
}

.telegram-button:after {
  display: none;
}

.telegram-wrapper.opened .telegram-button:before {
  display: none;
}

.telegram-wrapper.opened .telegram-button:after {
  display: block;
}

.telegram-wrapper.opened .telegram-button {
  box-shadow: 0 0 20px 0 rgba(0,0,0,.14);
}

.telegram-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
  color: #acacac;
  height: 35px;
  width: 35px;
  z-index: 102;
  &:before {
    content: '';
    border-right: 1px solid;
    position: absolute;
    height: 75%;
    right: -1px;
    width: 1px;
  }
  &:after {
    content: '';
    position: absolute;
    background: #fff;
    height: 15px;
    width: 100%;
    top: 100%;
    left: 0;
  }
}

.telegram-list {
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0,0,0,.14);
  position: absolute;
  max-width: 300px;
  width: max-content;
  z-index: 101;
  margin: 0;
  top: 100%;
  right: 0;

  @media all and (max-width: 768px) {
    bottom: 100%;
    left: 0;
    top: auto;
    width: max-content;
    max-width: 200px;

    li {
      text-align: left;
    }
  }

  li {
    border-bottom: 1px solid #eee;
    padding: 16px 14px;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
      color: #df6d17;
    }

    a {
      display: block;
    }
  }
}

.language-menu.opened {
  transition: box-shadow .3s ease-in-out .15s;
  box-shadow: 0 0 30px 0 rgba(0,0,0,.14);
}

.language-menu {
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  will-change: box-shadow;
  position: absolute;
  width: $lang-width;
  z-index: 1001;
  margin: 0;
  right: 0;
  top: 0;
  &:hover {
    cursor: pointer;
  }
}

.language-menu_item {
  transition: transform .3s ease-in-out;
  will-change: transform;
  transform: translateY(0);
  display: flex;
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  padding: 0 10px;
  height: 35px;
  width: 100%;
  top: 0;

  &:hover {
    cursor: pointer;

    .lm_item_text {
      text-decoration: underline;
      color: #df6d17;
    }
  }
}

ul>li.no-underline:hover {
  text-decoration: none;
}

.language-menu-icon {
  max-width: 100%;
  height: 20px;
  width: 20px;
}

.lm_item_text {
  font-size: 14px;
  margin-left: 5px;
}

.header-block {
  padding-top: 10px;
}

.nav-top {
  border-top: 1px solid #ededed;
  display: flex;
  margin: 0;

  li {
    font-size: 14px;
    color: #444342;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #df6d17;
    }
  }

  a {
    color: #444342;
    font-size: 14px;
    display: block;
    padding: 15px;
  }

  .active-nav {
    background: #f4f4f4;
  }
}

.search-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 245px;
  margin: 5px 0;
  width: 100%;
}

.search-block button {
  background: url('../svg/search.svg') 50% no-repeat #acacac;
  background-size: 60%;
  border-radius: 3px;
  height: 24px;
  width: 25px;
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.search-block input {
  background: #f4f4f4;
  border-radius: 3px 0 0 3px;
  padding: 3px 10px;
  font-size: 14px;
}

.header-nav {
  position: relative;
}

.nav-bot {
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  position: absolute;
  flex-wrap: wrap;
  z-index: 101;
  width: 100%;
  margin: 0;

  li {
    font-size: 14px;
    padding: 15px 30px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #df6d17;
    }
  }
}

.top-header {
  display: flex;
  justify-content: space-between;
}

.home-breadcrumb img {
  margin-right: 10px;
}

.line-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    margin-right: 10px;
  }
  a {
    display: block;
    > div {
      display: flex;
      align-items: center;
    }
  }
}

.breadcrumb-wrapper {
  padding: 30px 0 10px;
}

@media all and (max-width: 1080px) {
  .slider-carousel .slick-slider {
    .slide-title_text, .slide-title_subtext {
      font-size: 24px;
      line-height: 1.2;
      width: 85%;
    }
  }
}

@media all and (max-width: 768px) {
  .header-nav {
    display: none;
  }

  .opened-nav .mobile-top-header {
    padding-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
    max-width: 540px;
  }
}


.ScrollUpButtonContainer {
  position: fixed;
  right: -100px;
  bottom: 50px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: unset;
  font-size: 20px;
  padding: 10px;
  z-index: 1000;
}

.ScrollUpButtonContainer div {
  height: 58px;
  width: 58px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ScrollUpButtonContainer div:hover {
  background: rgba(223, 109, 23, 0.6);

  @media all and (max-width: 440px) {
    background: rgba(0, 0, 0, 0.2);
  }
}
.ScrollUpButtonContainer div .arrow {
  background: none;
  border-radius: 0;
  height: 20px;
  width: 20px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(-45deg);
  margin-top: 5px;
}

.ScrollUpButtonForTransition {
  right: 20px;
}
