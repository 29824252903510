.partners-wrapper,
.propositions-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  .page-title {
    padding: 30px 0;
    text-align: center;
  }
  &.iblocks {
    margin-bottom: 20px;
    h3 {
      font-size: 22px;
      font-weight: 700;
      color: #666;
      margin: 10px 0 15px 0;
    }
  }
}
.row.no-gutter.iblocks-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.propositions-wrapper.iblocks .secondary-slider .slick-slide > div {
  background: transparent;
  padding: 5px 5px 20px 5px;
}

.propositions-wrapper.iblocks .secondary-slider {
  height: 420px;
  .slick-arrow {
    bottom: -50px;
  }
  .slick-dots {
    bottom: -50px;
  }
}
.iblock-item-link {
  //border: 1px solid grey;
  //display: flex;
  height: 325px;
  padding: 10px;
  width: 204px;
  display: block;
  overflow: hidden;
  color: #666;
  &.static {
    margin-right: 10px;
    margin-bottom: 30px;
  }

  &:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, 0.3);
  }
}
.iblock-img-wrapper {
  width: 100%;
  height: 190px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
}
.iblock-caption {
  text-align: center;
  width: 100%;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.36;
  overflow: hidden;
  max-height: 106px;
  color: #666;
}

.propositions-item {
  background: #fff;
  height: 210px;
  //align-items: center;
  //justify-content: flex-start;
  padding: 10px;
  will-change: box-shadow;
  text-align: center;
  //display: flex;
  //flex-direction: column;
  margin: auto;
  width: 90%;
  &:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, .3);
    transition: all .3s;
    cursor: pointer;
  }
}

.no-pointer {
  pointer-events: none;
}

.propositions-wrapper {
  .secondary-slider .slick-slide > div {
    background: transparent;
  }
}

.services {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.propositions-image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;
}

.propositions-image {
  margin: 0 auto;
  max-width: 120px;
  width: auto;
  max-height: 130px;
}

.files-image {
  margin: 0 auto;
  max-width: 120px;
  width: auto;
  max-height: 130px;
}

.propositions-text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  margin: 10px auto 0;
  text-align: center;
  color: #5e5e5e;
  width: 130px;
  overflow: hidden;
  height: 55px;
}

@media screen and ( max-width: 440px ) {
  .iblocks .slick-arrow.slick-next {
    right: calc(50% - 70px);
  }
  .iblocks .slick-arrow.slick-prev {
    left: calc(50% - 70px);
  }
}

@media screen and ( max-width: 473px ) {
  .iblock-item-link.static {
    max-width: 45%;
    margin-bottom: 10px;
  }
  .row.no-gutter.iblocks-row {
    justify-content: space-between;
  }
  .propositions-wrapper.iblocks {
    justify-content: space-between;
  }
}

