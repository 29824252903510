@mixin spec-scroll {
  /* width*/
  ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, .15);
  }
  scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, .15);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #444342;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #858585;
  }
}

.accordion-button {
  padding: 20px 0;
}

.filters-select-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 5;
}

.datepicker-wrapper {
  border-right: 1px solid #ddd;
  padding: 25px 15px 15px 15px;
  flex: 1.8;
}

.datepicker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.accordion-wrapper.opened {
  .accordion-head {
    box-shadow: 0 12px 24px 0 rgba(0,0,0,.15);
  }
}

.accordion-wrapper {
  position: relative;
  .accordion-head {
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0);
    padding: 10px 15px;
    min-width: 190px;

    .search-block {
      max-width: 335px;
      .search-submit {
        background-size: 60%;
        height: 35px;
        width: 35px;
      }
      input {
        height: 35px;
      }
    }
  }

  .accordion-body {
    display: flex;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,.15);
    justify-content: space-between;
    width: calc(100% - 20px);
  }
}

.filters-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 160px;
  overflow-y: auto;
}

.filters-item {
  display: flex;
  flex-wrap: wrap;
  width: 33%;
  padding: 10px 10px 10px 0;

  label {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
    p {
      flex: 1;
    }
  }

  input:checked + .checkbox-facade {
    background: #df6d17;
    background-image: url(../svg/CheckBox.svg);
  }
}

.checkbox-wrapper {
  display: flex;
  margin-right: 10px;
}

.checkbox-wrapper input {
  height: 0;
  width: 0;
}

.checkbox-facade {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #e9e9e9;
}

.divider {
  font-size: 24px;
  color: #acacac;
}

.dp-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;

  input {
    border-radius: 6px;
    background: #f6f6f6;
    text-align: center;
    padding: 3px 10px;
    font-size: 14px;
    color: #444342;
    height: 35px;
    width: 45%;
    cursor: pointer;
  }

  input.active {
    background: #df6d17;
    color: #fff;
  }
}

.overflow {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.periods-wrapper {
  border-bottom: 1px solid #ddd;
  padding: 25px 15px 15px 15px;
  @include spec-scroll;
}

.tags-wrapper {
  @include spec-scroll;
  padding: 15px;
}
