@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@-ms-viewport {
  width: device-width;
  initial-scale: 1; }

@-moz-viewport {
  width: device-width;
  initial-scale: 1; }

@-webkit-viewport {
  width: device-width;
  initial-scale: 1; }

* {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 14px; }

*, :before, :after {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  position: relative;
  min-height: 100%;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  text-decoration-skip: objects;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
  display: block; }

video, audio, canvas {
  display: inline-block; }

span {
  display: inline-block; }

a, input, button, textarea, optgroup, select, fieldset, figure, legend, address {
  font: inherit;
  color: inherit;
  line-height: inherit;
  text-transform: inherit;
  text-shadow: inherit;
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding: 0; }

a, button, [type='button'], [type='submit'] {
  transition: color .25s, background .25s, opacity .25s; }

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-text-decoration-skip: none; }

button {
  overflow: visible;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit; }

p {
  margin: 0;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow: hidden; }

b, strong {
  font-weight: 700; }

iframe {
  vertical-align: middle;
  max-width: 100%;
  border: 0; }

.page-iframe {
  text-align: center; }

img, svg, embed, object, video, audio, canvas {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  border: 0; }

img {
  image-rendering: optimizeQuality; }

svg {
  color: inherit; }
  svg:not(:root) {
    overflow: hidden; }

progress {
  vertical-align: baseline; }

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility;
  color: inherit;
  margin: 0; }

ul, ol {
  padding: 0;
  list-style: none; }

input, select, button, button > *, a > * {
  display: inline-block;
  vertical-align: middle; }

button, label, select, summary, [type='button'], [type='submit'], [type='reset'], [type='checkbox'], [type='radio'], [type='range'] {
  user-select: none;
  cursor: pointer; }

[type='button'], [type='submit'], [type='search'], [type="radio"], [type='range'], ::-webkit-search-cancel-button, ::-webkit-search-decoration, ::-webkit-outer-spin-button, ::-webkit-inner-spin-button, ::-webkit-slider-thumb {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

::-webkit-contacts-auto-fill-button, ::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0; }

:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #fff; }

::-webkit-details-marker {
  display: none; }

[type="radio"] {
  -webkit-appearance: radio; }

[type='number'] {
  -moz-appearance: textfield; }

::-ms-clear, ::-ms-reveal {
  display: none; }

input, textarea {
  width: 100%; }

input {
  text-overflow: ellipsis; }

textarea {
  overflow: auto;
  resize: none; }

:active, :hover, :focus {
  outline: 0;
  outline-offset: 0; }

:disabled {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-text-fill-color: inherit; }

::-moz-focus-outer, ::-moz-focus-inner {
  border: 0;
  padding: 0; }

::placeholder {
  opacity: 1;
  color: inherit; }

::-moz-placeholder {
  opacity: 1;
  color: inherit; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td, th {
  padding: 0;
  text-align: center; }

article p:not(:empty) {
  margin-top: .7em;
  margin-bottom: .7em; }

article img, article embed, article object, article iframe, article video {
  display: block;
  margin: auto; }

article span {
  display: inline; }

article ul, article ol {
  margin-top: .7em;
  margin-bottom: .7em; }
  article ul ul, article ul ol, article ol ul, article ol ol {
    margin: 0;
    padding-left: 1.2em; }
  article ul > li:before, article ol > li:before {
    margin-right: .7em; }
  article ul > li p, article ol > li p {
    display: inline; }

article ul > li:before {
  content: '•'; }

article ol {
  counter-reset: count; }
  article ol > li:before {
    content: counter(count) ".";
    counter-increment: count; }
