@mixin aff-boss-text {
  max-width: 245px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.1px;
  color: #444342;
}

.affiliate-address-director {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.affiliate-office-contact {
  width: 49%;
  //height: 281px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.affiliate-director-contact {
  @extend .affiliate-office-contact;
  padding-top: 21px;
  padding-left: 15px;
  padding-bottom: 30px;
}

.affiliate-contacts {
  text-align: left;
  padding: 20px 0 0 15px;
}

.affiliate-boss-identity  {
  display: flex;
  margin-top: 12px;
  span {
    @include aff-boss-text;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #444342;
    }
  }
}

.affiliate-street-spot {
  display: flex;
  span {
    @include aff-boss-text;
  }
}

.affiliate-boss-image {
  width: 90px;
}

.aff-map-point-icon {
  margin-right: 15px;
}

.boss-name-block {
  margin-left: 5px;
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: #444342;
    margin-left: 10px;
  }
}

.affiliate-common-header {
  font-size: 22px;
  font-weight: 600;
  color: #202124;
  background-color: #ffffff;
}

.organization-explain-block {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-top: 25px;
  padding: 15px;
  p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: 0.1px;
    color: #444342;
  }
}

.structure-row {
  display: flex;

  span {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    letter-spacing: 0.1px;
    color: #444342;
    margin-left: 10px;
  }

  .arrow-right-black {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #0a0a0a;
    margin-top: 9px;
  }
}

.one-indentation {
  margin-top: 12px;
}

.two-indentation {
  padding-left: 12px;
}

.organization-security-block {
  margin-top: 25px;
}

.affiliate-security-director {
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;
  margin-bottom: 12px;
}

.security-boss-name-block {
  margin-left: 10px;
}

.security-boss-name-header {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #202124;
  margin-bottom: 12px;
}

.security-contact-top-intend {
  margin-top: 25px;
  margin-bottom: 14px;
}

.department-main-direction {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342;
}

.tel-numbers {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  white-space: nowrap;
  color: #444342;
  p {
    @extend .tel-numbers;
  }
}

.affiliate-mediate-name {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: #202124;
}


@media only screen and (max-width: 800px) {
  .affiliate-address-director {
    flex-wrap: wrap;
  }
  .affiliate-office-contact {
    width: 100%;
  }
  .affiliate-mediate-name {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #202124;
  }
}

@media only screen and (max-width: 400px) {
  .affiliate-address-director {
    display: block;
  }
  .affiliate-office-contact {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }

  .affiliate-director-contact {
    width: 100%;
  }
  .news-item-wrapper {
    padding-right: 0;
  }
  .affiliate-boss-identity {
    display: block;
  }
  .boss-name-block {
    display: flex;
  }
  .tel-numbers {
    p {
      font-size: 14px;
    }
  }
  .breadcrumb-wrapper {
    display: none;
  }
  .affiliate-common-header {
    font-size: 16px;
    font-weight: 600;
    color: #202124;
  }
  .affiliate-mediate-name {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #202124;
  }
  .affiliate-security-director {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}
