@media screen and (max-width: 1280px) {
  .App-service {
    .services-item_wrapper {
      margin-bottom: 25px;
      width: 20%;
    }
  }

  .main-content-block {
    .news-content-block {
      .last-news-item {
        display: flex;
        justify-content: center;
      }

      .last-news-item_big {
        width: 50%;
      }

      .last-news-item_small {
        width: 25%;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .main-content-block {
    .news-content-block {
      justify-content: space-between;
      .last-news-item {
        display: flex;
        justify-content: center;
      }

      .last-news-item_big {
        width: 49%;
      }

      .last-news-item_small {
        width: 25%;
      }
    }

    //.big-news-title  * {
    //  font-size: 20px;
    //}

    //.big-news-description  * {
    //  font-size: 12px;
    //}
  }
}

@media screen and (max-width: 840px) {
  .home-page .App-service {
    justify-content: center;
    .services-item_wrapper {
      width: 35%;
    }
  }

  .main-content-block {
    .news-content-block {
      .last-news-item {
        display: flex;
        justify-content: center;
      }

      .last-news-item_big {
        width: 49%;
      }

      .last-news-item_small {
        width: 24%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mobile-top-header {
    padding-bottom: 10px;
  }

  .main-slider {
    .slick-slider {
      height: 445px;
      .slick-slide {
        display: flex;
        align-items: stretch;
        overflow: hidden;
        img {
          position: absolute;
          max-width: initial;
          height: 100%;
          width: auto;
          bottom: 0;
          top: 0;
        }
      }
    }
  }

  .home-page {
    .App-service {
      justify-content: space-around;

      .services-item_wrapper {
        width: 40%;
        padding: 0;
      }

      .services-item {
        max-height: 185px;
        padding-bottom: 25px;
        .services-text {
          font-size: 12px;
          margin-top: 20px;
          padding: 0;
          height: auto;
        }
      }
    }

    .secondary-slider.last-news-slider {
      height: 527px;
      .slick-dots,
      .slick-arrow {
        bottom: -60px;
      }
    }

    .cutted-hr {
      width: 100%;
    }

    .news-double-item {
      max-width: 100%;
      margin-top: 10px;
      margin-bottom: 18px;
    }

    .big-card {
      max-width: 100%;
      //height: auto;
    }

    .big-news-image {
      //height: auto;
    }

    .last-news-slider {
      .slick-track {
        align-items: flex-start;
      }

    }

    .propositions-item {
      margin: 0 auto;
    }
  }

  .home-page .main-content-block {
    padding: 10px 0 30px;
    .news-content-block {
      .last-news-item {
        display: flex;
        justify-content: center;
      }

      .last-news-item_big {
        width: 49%;
      }

      .last-news-item_small {
        width: 24%;
      }
    }

    .big-news-description {
      //font-size: 14px;
      //line-height: 16px;
      //margin: 20px 10px;
    }

    .big-news-title  {
      //padding: 10px 10px 0 10px;
    }

    .big-news-title  * {
      //font-size: 14px;
    }

    .secondary-slider {
      margin-left: 0;
      padding-left: 0;
    }

    .partners-wrapper .page-title,
    .propositions-wrapper .page-title {
      padding: 20px 0;
      font-size: 22px;
    }
  }
}
