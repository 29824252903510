.small-news-image {
  max-height: 116px;
  width: 100%;
}

.small-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 15px 20px 0 rgba(0,0,0,.05);
  padding-bottom: 20px;
  position: relative;
  background: #fff;
  max-width: 208px;
  height: auto;
  width: 100%;
  &:hover {
    box-shadow: 0 15px 25px 0 rgba(223,109,23,.25);
    transition: all .3s;
    cursor: pointer;
  }

  .news-bottom {
    top: 10px;
  }

  .book-image_wrapper {
    background: #ddd;
    height: 290px;
    position: relative;
  }

  a {
    display: flex;
    flex-direction: column;
  }
}

.small-news-title {
  margin: 15px 15px 0 15px;
  line-height: 1.57;
  font-weight: 600;
  font-size: 14px;
  color: #202124;
  height: 45px;
  &:hover * {
    cursor: pointer;
    //text-decoration: underline;
  }
}

.small-news-description {
  margin: 15px 15px 0 15px;
  height: 100px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: .5px;
  color: #444342;
  overflow: hidden;
}

.compact-tags {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../svg/tag.svg") 50% no-repeat;
  background-size: contain;
  font-size: 12px;
  color: #444342;
  height: 20px;
  width: 30px;
}

.currency-editions {
  position: absolute;
  bottom: 104px;
  left: 0;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background: #df6d17;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding: 3px 15px
}
