.org-structure {
  display: flex;
  width: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.org-structure-panel {
  width: 50%;
  padding: 20px;
  height: 570px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .active {
    background-color: #f8ca7f;
  }

  .parent {
    padding: 18px;
    text-align: center;
    font-size: 12px;
    line-height: 1.33;
    color: #444342;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      border: solid 1px #df6d17;
    }
  }

  .parent-nodes {
    padding: 20px;
    overflow: auto;
  }

  &.parent-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    padding: 0;
  }

  &.children-panel {
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .children {
    flex-basis: calc(50% - 5px);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    cursor: pointer;

    &:nth-child(odd) {
      margin-right: 10px;
      flex-basis: calc(50% - 5px);
    }

    & p {
      font-size: 12px;
      line-height: 1.33;
      color: #444342;
      margin-right: 10px;
    }
    &:hover {
      border: solid 1px #df6d17;
    }

    @media screen and (max-width: 995px) {
      flex-basis: 100%;

      &:nth-child(odd) {
        flex-basis: 100%;
        margin-right: 0;
      }
    }
  }

  .arrow {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #df6d17;
  }

  .section-contacts {
    max-height: 450px;
  }

  .org-fold {
    display: flex;
    justify-content: space-between;
    min-height: 70px;
    border-top: 1px solid #eee;
    padding-left: 20px;

    .folder-button {
      cursor: pointer;
      padding-bottom: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-shrink: 0;
      min-height: 68px;
      width: 55px;
    }

    .folder-title {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        margin-top: 10px;
        margin-bottom: 5px;
        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .link {
      font-size: 18px;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .org-fold-cont {
    padding: 20px;
    padding-top: 0;
    overflow-y: auto;
    max-height: 300px;

    .address-block {
      padding-top: 0;
      display: flex;
    }

    .address {
      margin-left: 5px;
      color: #444342;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        padding: 3px 0;
      }
    }

    .picture {
      flex-shrink: 0;
      width: 30%;
    }

    .avatar {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden;
    }

    .location {
      margin-left: 25px;
      width: 20px;
      height: 25px;
      flex-shrink: 0;
    }

    .contacts {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .one-contact {
        flex-shrink: 0;
        min-width: calc(50% - 5px);
        display: flex;
        margin: 5px 0;
      }
    }
  }

  .position {
    display: flex;
    margin-bottom: 10px;

    .arrow {
      margin-top: 5px;
    }

    span {
      font-size: 14px;
      line-height: 1.5;
      margin-left: 10px;
    }
  }
}

.org-structure-person {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;

  .name > a {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #444342;
  }

  .position {
    display: flex;
    align-items: center;
    &>div {
      margin-right: 10px;
    }
    &>span {
      line-height: 1.33;
      color: #444342;
    }
  }

  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }
}

.org-contact-block {
  .adr-block {
    margin: 0 -10px;
  }
}
