.error-page_wrapper {
  min-height: 400px;
  max-width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px auto 120px;
  width: 100%;
}

.error-page_title {
  font-size: 200px;
  font-weight: 600;
  color: #444342;
}

.error-page_text {
  font-size: 38px;
  font-weight: 600;
  color: #444342;
  text-align: center;
  margin-bottom: 20px;
}

.error-page_additional {
  font-size: 22px;
  font-weight: 500;
  color: #444342;
  text-align: left;
  margin-top: 20px;
}

.error-page_link {
  font-size: 22px;
  font-weight: 500;
  color: #df6d17;
  text-align: center;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.error-text-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.error-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  flex: 1;
}

.error-page_icon {
  margin: 0 auto;
  width: 60%;
}

.error-text-wrapper.localization-error {
  align-items: flex-start;
  flex: 1.7;
  .error-page_text,
  .error-page_link {
    text-align: left;
  }
}

@media screen and (max-width: 1080px) {
  .error-page_wrapper {
    flex-direction: column-reverse;
    margin: 40px auto 60px;
    flex-wrap: wrap;
  }

  .error-text-wrapper.localization-error {
    align-items: center;
    margin-top: 10px;
    .error-page_text,
    .error-page_link {
      text-align: center;
    }
    .error-page_additional {
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .error-page_wrapper {
    max-width: 100%;
  }

  .error-page_title {
    font-size: 100px;
  }

  .error-page_text {
    font-size: 28px;
  }

  .error-page_link {
    font-size: 18px;
  }
}
