$arrow-width: 50px;

.home-page .secondary-slider {
  height: 300px;
}

.secondary-slider_empty {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;
  width: 100%;
}

.editions-page .secondary-slider .slick-slide > div {
  max-height: 390px;
}

.editions-page .secondary-slider {
  .slick-next {
    @media all and (max-width: 440px) {
      right: calc(50% - 60px);
    }
  }
  .slick-prev {
    @media all and (max-width: 440px) {
      left: calc(50% - 60px);
    }
  }
}

.news-item-related .secondary-slider .slick-slide > div {
  padding-right: 15px;
  height: 375px;
}

.secondary-slider {
  overflow: hidden;
  margin-left: -20px;
  padding-left: 20px;

  .slick-slider {
    width: 100%;
  }

  .slick-list {
    overflow: visible;
    //height: 62vh;
  }

  .slick-slide > div {
    background: #fff;
    text-align: left;
  }

  //.slick-slide.slick-cloned {
  //  display: none;
  //}

  .slick-arrow {
    background: url('../svg/arrow-grey.svg') 50% no-repeat;
    background-size: 8px;
    position: absolute;
    font-size: 0;
    height: 50px;
    width: $arrow-width;
    bottom: -67px;
    z-index: 10;
    &:hover {
      background: url('../svg/arrow-hover.svg') 50% no-repeat;
      background-size: 8px;
    }
  }

  .slick-prev {
    left: calc(50% - 140px);
    &:hover {
      &:after {
        content: '';
        border-radius: 0 50px 50px 0;
        border-bottom: 1px solid #5e5e5e;
        border-right: 1px solid #5e5e5e;
        border-top: 1px solid #5e5e5e;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 50px 0 0 50px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
      }
    }
  }

  .slick-next {
    transform: rotate(180deg);
    right: calc(50% - 140px);
    &:hover {
      &:after {
        content: '';
        border-radius: 0 50px 50px 0;
        border-bottom: 1px solid #5e5e5e;
        border-right: 1px solid #5e5e5e;
        border-top: 1px solid #5e5e5e;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 50px 0 0 50px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
        left: 1px;
      }
    }
  }

  .slick-prev:after,
  .slick-next:after {
    content: '';
    border: 1px solid #5e5e5e;
    border-radius: 50%;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    top: 1px;
    right: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    justify-content: center;
    bottom: -70px;
    right: 0;
    left: 0;

    li {
      position: relative;
      margin: 5px;
      font-size: 0;
      height: 15px;
      width: 15px;
      &:hover {
        border: 1px solid #df6d17;
        border-radius: 50%;
        cursor: pointer;
      }
      &:after {
        content: '';
        background: #5e5e5e;
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }

      button {
        position: relative;
        z-index: 10;
        height: 100%;
        width: 100%;
      }
    }

    .slick-active:after {
      background: #df6d17;
    }
  }
}

@media all and (max-width: 440px)  {
  .secondary-slider .slick-slide > div {
    display: flex;
    justify-content: center;
  }
}
