$line-color: #ededed;

.accordion {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #fff;
}

.partners-wrap {
  .fold {
    .fold_trigger {
      padding: 24px 20px;

      i {
      font-size: 16px;
      margin-top: 5px;
      }

      img {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin: 0 10px 0 0;
        object-fit: cover;
      }

    }

    .partners-title {
      font-size: 22px;
      font-weight: 600;

      @media all and (max-width: 440px) {
        font-size: 16px;
        max-width: 224px;
      }

    }
  }


  .folder-item {
    padding: 15px 20px;
  }

  .accordion-row-content {
    max-width: calc(100% - 93px);
  }

  .partner-description {
    font-size: 14px;
  }
}

.fold {

  &:first-child {
    border-top: none;
  }

  .fold-title {
    font-size: 24px;
    font-weight: 800;
    height: 27px;
    padding-left: 30px;
  }
  .fold_trigger {
    text-align: start;
    width: 100%;
    padding: 24px 0;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    color: #202124;

    &.open {
      background-color: #ffeccc;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: #444342;
    }
    i {
      float: right;
    }

    .partner-counter {
      float: right;
      width: 30px;
      display: flex;
      justify-content: space-between;
    }
  }

  .fold_content {
    display: none;
    max-height: 0;
    opacity: 0;
    transition: max-height 400ms linear;

    &.open {
      display: block;
      max-height: 673px;
      opacity: 1;
      overflow-y: auto;
    }
  }
  border-top: 1px solid $line-color;
  //padding: 0 1em 0 2em;
}

.about-partner-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  margin-right: 25px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.folder-item {
  display: flex;
  //padding: 10px;
  border-bottom: 1px solid #ededed;
  &:last-child {
    border: none;
  }
}

.accordion-row-content {
  //margin-left: 25px;
  max-width: 100%;
  //max-width: 730px;
  color: #202124;
  span {
    font-size: 22px;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
      color: #df6d17;
    }
  }
}

.partners-wrap {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  margin-top: 25px;
}

.affiliates-address {
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342;
}

.accordion-body {
  background: #fff;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: calc(100% + 1px);
}

.accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .05);
}

.accordion-button {
  padding: 20px 15px;
  &:hover {
    cursor: pointer;
  }
}

.accordion-wrapper {
  padding-right: 20px;
}
