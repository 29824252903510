.editions-page {
  .secondary-slider:before {
    width: 12px;
  }
}

.no-left-gutter {
  margin-left: 0;
}

.books-category-header {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.1;
  color: #202124;
}

.content-item-wrapper {
  width: 100%;
}

.book-image {
  max-height: 290px;
  width: 100%;
}

.book-text_wr {
  height: 95px;
  padding: 8px 15px 10px 15px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .6), #fff);
  font-weight: 600;
  color: #202124;
  position: absolute;
  width: 100%;
  bottom: 74px;
  max-height: 96px;
  font-size: 14px;
  line-height: 1.71;
  overflow: hidden;
  //&:hover {
  //  text-decoration: underline;
  //  cursor: pointer;
  //}
}

.books-category {
  margin-top: 25px;
  margin-bottom: 27px;
}

.book-view-header {
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.26;
  color: #202124;
  margin: 10px 0 20px 0;
}

.product-book-block {
  display: flex;
  justify-content: space-between;

  .product-book-image {
    max-width: 270px;
    //background: #ddd;
    width: 100%;

    img {
      height: auto;
      width: auto;
      max-height: 360px;
      max-width: 100%;
      //object-fit: contain;
    }
  }

  .product-book-info {
    flex: 1;
    padding: 0 30px 0 30px;

    table {
      table-layout: fixed;
      width: 100%;
    }
    td {
      text-align: left;
    }
    td:first-child {
      overflow: hidden;
      width: 35%;
    }

    .book-table-key {
      height: 25px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #acacac;
      padding: 5px 0;
      vertical-align: baseline;
    }

    .book-table-value {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #5e5e5e;
      vertical-align: baseline;
      padding: 5px 0;

      span + span {
        &:before {
          content: ',';
          padding-right: 5px;
        }
      }
    }
  }
}

.product-price-block {
  text-align: left;
  margin-top: 24px;
}

.comment-rating-ratio {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #202124;
  margin-left: 15px;
}

.book-buy-button {
  flex-shrink: 0;
  width: 250px;
  height: 49px;
  border-radius: 100px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, .12);
  background-color: #df6d17;
  padding: 10px 0 10px 0;
  margin-top: 18px;

  span {
    height: 29px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-top: 1px;
    &:hover {
      //text-decoration: underline;
      cursor: pointer;
      color: #fff;
    }
  }
}

.edition-block-description {
  padding-right: 20px;

  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
}

.product-book-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: .1px;
  color: #666;
  margin-top: 25px;

}

.product-book-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.book-comments-header {
  display: flex;
  margin-top: 25px;
  height: 46px;
  align-items: center;

  .comment-main-header {
    height: 27px;
    font-size: 22px;
    font-weight: 600;
    color: #202124;
  }
  .comments-ratio-numbers {
    @extend .comment-main-header;
    color: #acacac;
    margin-left: 20px;
  }

  .book-leave-comment-block {
    width: 73px;
    height: 46px;
    border-radius: 93.9px;
    box-shadow: 0 6px 17px 0 rgba(0, 0, 0, .12);
    background-color: #df6d17;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    text-align: center;
    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }
}

.book-comment-block {
  margin-top: 12px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .1);
  background: #fff;
  width: 100%;
  margin-right: 24px;

  .comment-content-block {
    font-size: 14px;
    line-height: 1.57;
    color: #202124;
  }

  .comment-block-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .comment-block-item {
    border-bottom: 1px solid #ededed;
    padding: 20px;
  }

  .comment-author {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    color: #444342;
    text-align: left;
  }
}

.rating-comment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 150px;
  font-size: 14px;
}

.row-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;

  &.with-arrows {
    .secondary-slider {
      padding-bottom: 85px;
    }
  }

  .secondary-slider {
    height: auto;
  }

  .small-card {
    width: 92%;
    //margin-right: 20px;
    overflow: hidden;
  }
}

.with-hr {
  position: relative;
  margin-left: -10px;
  padding-left: 15px;
  &:after {
    content: '';
    position: absolute;
    background: #202124;
    left: 10px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 20px);
  }
}

.comment-tag-block {
  margin-top: 26px;
}

.books-bottom-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.books-rate-tags-block {
  display: flex;
  height: 15px;
  font-size: 12px;
  color: #444342;
  margin-left: 5px;
  align-items: center;
  bottom: 20px;
  margin-top: 10px;
  width: 100px;
}

.book-share-wrapper {
  position: relative;
  margin: 0 15px 0 0;
  height: 30px;
  width: 35px;
  padding-top: 10px;
}

.book-tag-num {
  font-size: 12px;
  font-weight: 100;
}

.book-filter-block-wrapper {
  width: 100%;
}

.editions-big-name {
  height: 58px;
  font-size: 36px;
  font-weight: 600;
  color: #202124;
  text-align: center;
}

.accordion-button-books {
  img {
    transition: transform .3s;
    transform: rotate(-90deg);
    margin-left: 15px;
  }
}

.books-accordion-wrapper.opened {
  .accordion-button-books {
    img {
      transition: transform .3s;
      transform: rotate(90deg);
      margin-left: 15px;
    }
  }
  accordion-head {
    flex-wrap: wrap;
  }
}

.book-item {
  //min-height: 380px;
  margin: 8px 0;
  width: 100%;
}

.in-slider {
  margin-left: 20px;
}

.not-found-text {
  font-size: 22px;
  padding: 20px 0;
}

@media only screen and (max-width: 510px) {
  .book-view-header {
    font-size: 18px;
    margin-bottom: 20px;
    height: auto;
  }
  .product-book-block {
    .product-book-info {
      padding-left: 10px;
    }
    .product-book-image {
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 399px) {
  .single-centered {
    //margin-left: 30px;
  }
  .books-filter-row {
    flex-wrap: wrap;
  }
  .books-filter-tags-wrapper {
    .filters-list {
      .filters-item {
        width: 50%;
      }
    }
  }
  .books-category-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #202124;
    margin-left: 30px;
    margin-bottom: -20px;
  }

  .product-book-block {
    .product-book-info {
      table {
        table-layout: fixed;
        width: 100%;
        margin-top: 5px;
      }
      td {
        text-align: left;
      }
      td:first-child {
        overflow: hidden;
        width: 50%;
        vertical-align: top;
        padding-right: 30px;
        padding-top: 3px;
      }
    }
  }
}

.filtered-books-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

// rating
.rating {
  display: inline-block;
  //margin-left: 10px;
}

.star-icon {
  background: url("../../svg/star-grey.svg") 50% no-repeat;
  background-size: cover;
  margin-right: 2px;
  height: 10px;
  width: 10px;
}

.star-icon.full {
  background: url("../../svg/star-fill.svg") 50% no-repeat;
}

.star-icon.half {
  background: url("../../svg/star-half.svg") 50% no-repeat;
}

// modals

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;

  &.display-block {
    display: block;
  }

  &.display-none {
    display: none;
  }
}

.modal-background {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.modal-book-content {
  position: fixed;
  background: white;
  width: 700px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 50px;
  z-index: 20;

  @media screen and (max-width: 768px) {
    width: 85%;
    //min-width: 400px;
    position: absolute;
    transform: none;
    top: auto;
    left: auto;
    overflow: auto;
    height: 100%;
  }
  //@media screen and (max-width: 500px) {
  //  width: 100%;
  //}

  .react-rater {
    white-space: nowrap;
    height: 50px;
    margin-top: 5px;

    div {
      display: inline-block;

      +div {
        margin-left: 12px;
      }
    }
  }

  .react-rater-star {
    font-size: 53px;
    line-height: 56px;
    color: #ededed !important;

    &.is-active {
      color: #ffd543 !important;
    }

    &.will-be-active {
      color: #f8ca7f !important;
    }
  }

  .close-btn {
    float: right;
    height: 27px;

    img {
      height: 27px;
    }
  }

  form {
    text-align: center;
  }

  .modal-header {
    text-align: center;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    color: #202124;
    display: inline-block;
    margin-bottom: 25px;
  }

  input {
    height: 46px;
    border-radius: 6px;
    border: solid 1px #ededed;
    background-color: #f6f6f6;
    padding: 16px 24px;
    font-size: 14px;
    color: #202124;
    margin-top: 11px;

    &.error {
      border-color: #df6d17;
    }
  }

  textarea {
    &.error {
      border-color: #df6d17;
    }
  }

  label {
    color: #202124;
    font-size: 12px;
    font-weight: bold;
    width: 47%;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .textarea-label {
    width: 100%;
  }

  .form-group-textarea {
    width: 100%;
    text-align: left;
  }

  .form-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;
  }
}

.book-delivery-types {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);
  margin-top: -2px;
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #ffffff;

  li {
    padding: 16px 14px;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
      color: #df6d17;
    }
  }
}

.rotating-loading {
  text-align: center;
  width: 100%;
  margin: 25px 0 0;

  img {
    -webkit-animation: rotating 4s linear infinite;
    -moz-animation: rotating 4s linear infinite;
    -ms-animation: rotating 4s linear infinite;
    -o-animation: rotating 4s linear infinite;
    animation: rotating 4s linear infinite;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
