@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/v4-shims.css);
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.min-height-100 {
  min-height: 100vh; }

@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@-ms-viewport {
  width: device-width;
  initial-scale: 1; }

@-moz-viewport {
  width: device-width;
  initial-scale: 1; }

@-webkit-viewport {
  width: device-width;
  initial-scale: 1; }

* {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 14px; }

*, :before, :after {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  position: relative;
  min-height: 100%;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  -webkit-text-decoration-skip: objects;
          text-decoration-skip: objects;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation; }

article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
  display: block; }

video, audio, canvas {
  display: inline-block; }

span {
  display: inline-block; }

a, input, button, textarea, optgroup, select, fieldset, figure, legend, address {
  font: inherit;
  color: inherit;
  line-height: inherit;
  text-transform: inherit;
  text-shadow: inherit;
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding: 0; }

a, button, [type='button'], [type='submit'] {
  -webkit-transition: color .25s, background .25s, opacity .25s;
  transition: color .25s, background .25s, opacity .25s; }

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-text-decoration-skip: none; }

button {
  overflow: visible;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit; }

p {
  margin: 0;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow: hidden; }

b, strong {
  font-weight: 700; }

iframe {
  vertical-align: middle;
  max-width: 100%;
  border: 0; }

.page-iframe {
  text-align: center; }

img, svg, embed, object, video, audio, canvas {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  border: 0; }

img {
  image-rendering: optimizeQuality; }

svg {
  color: inherit; }
  svg:not(:root) {
    overflow: hidden; }

progress {
  vertical-align: baseline; }

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility;
  color: inherit;
  margin: 0; }

ul, ol {
  padding: 0;
  list-style: none; }

input, select, button, button > *, a > * {
  display: inline-block;
  vertical-align: middle; }

button, label, select, summary, [type='button'], [type='submit'], [type='reset'], [type='checkbox'], [type='radio'], [type='range'] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }

[type='button'], [type='submit'], [type='search'], [type="radio"], [type='range'], ::-webkit-search-cancel-button, ::-webkit-search-decoration, ::-webkit-outer-spin-button, ::-webkit-inner-spin-button, ::-webkit-slider-thumb {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

::-webkit-contacts-auto-fill-button, ::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0; }

:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #fff; }

::-webkit-details-marker {
  display: none; }

[type="radio"] {
  -webkit-appearance: radio; }

[type='number'] {
  -moz-appearance: textfield; }

::-ms-clear, ::-ms-reveal {
  display: none; }

input, textarea {
  width: 100%; }

input {
  text-overflow: ellipsis; }

textarea {
  overflow: auto;
  resize: none; }

:active, :hover, :focus {
  outline: 0;
  outline-offset: 0; }

:disabled {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-text-fill-color: inherit; }

::-moz-focus-outer, ::-moz-focus-inner {
  border: 0;
  padding: 0; }

::-webkit-input-placeholder {
  opacity: 1;
  color: inherit; }

::-moz-placeholder {
  opacity: 1;
  color: inherit; }

::-ms-input-placeholder {
  opacity: 1;
  color: inherit; }

::placeholder {
  opacity: 1;
  color: inherit; }

::-moz-placeholder {
  opacity: 1;
  color: inherit; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td, th {
  padding: 0;
  text-align: center; }

article p:not(:empty) {
  margin-top: .7em;
  margin-bottom: .7em; }

article img, article embed, article object, article iframe, article video {
  display: block;
  margin: auto; }

article span {
  display: inline; }

article ul, article ol {
  margin-top: .7em;
  margin-bottom: .7em; }
  article ul ul, article ul ol, article ol ul, article ol ol {
    margin: 0;
    padding-left: 1.2em; }
  article ul > li:before, article ol > li:before {
    margin-right: .7em; }
  article ul > li p, article ol > li p {
    display: inline; }

article ul > li:before {
  content: '\2022'; }

article ol {
  counter-reset: count; }
  article ol > li:before {
    content: counter(count) ".";
    counter-increment: count; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Regular.a34a420b.woff2) format("woff2"), url(/static/media/Montserrat-Regular.4fff79a0.woff) format("woff"), url(/static/media/Montserrat-Regular.bb40fa8b.ttf) format("truetype");
  /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-SemiBold.11fc7168.woff2) format("woff2"), url(/static/media/Montserrat-SemiBold.bbf7b03b.woff) format("woff"), url(/static/media/Montserrat-SemiBold.554de18f.ttf) format("truetype");
  /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Italic.10923863.woff2) format("woff2"), url(/static/media/Montserrat-Italic.ff771338.woff) format("woff"), url(/static/media/Montserrat-Italic.68024561.ttf) format("truetype");
  /* Safari, Android, iOS */
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-SemiBoldItalic.ac4e1dce.woff2) format("woff2"), url(/static/media/Montserrat-SemiBoldItalic.54765b19.woff) format("woff"), url(/static/media/Montserrat-SemiBoldItalic.6852e42e.ttf) format("truetype");
  /* Safari, Android, iOS */
  font-weight: 600;
  font-style: italic; }

#root {
  height: 100%;
  display: flex;
  flex-direction: column; }

.App {
  flex: 1 0 auto; }

.App-header {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); }

.App-footer {
  flex-shrink: 0; }

.logo-container {
  width: auto;
  height: 60px; }
  .logo-container img {
    max-width: 100%;
    width: 100%; }

.App-service {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between; }

.line-breadcrumb .home-breadcrumb i {
  color: #df6d17; }

.line-breadcrumb .active {
  height: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #df6d17; }

.modal-wrapper {
  display: flex;
  background: rgba(32, 33, 36, 0.8);
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1001;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0; }

.modal-content {
  position: fixed;
  max-width: 840px;
  margin: 0 auto;
  width: 80%;
  right: 0;
  left: 0; }

hr {
  display: block;
  margin: 1em 0;
  height: 1px;
  padding: 0;
  border: 0;
  border-top: 1px solid rgba(68, 67, 66, 0.8); }

.fa, .fab {
  font-size: 20px; }

.no-active {
  pointer-events: none; }

.iframe-container {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden; }

.iframe-container iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
  left: 0;
  top: 0; }

.line-breadcrumb a {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.off-pointer {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  width: 100%; }

.off-pointer *:hover {
  cursor: default !important; }

.social-icon {
  color: #acacac; }

footer .social-icons {
  margin: 0; }

.social-icons {
  margin: 0 75px 0 0;
  display: flex; }
  .social-icons li {
    height: 35px;
    width: 35px;
    position: relative;
    display: flex;
    align-items: center; }
    .social-icons li:first-child:after {
      content: '';
      color: #acacac;
      position: absolute;
      height: 75%;
      width: 1px;
      left: 0; }
    .social-icons li a {
      text-decoration: none;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #acacac;
      height: 100%;
      width: 100%; }

.fa-telegram-plane {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-telegram-plane:hover {
    color: #0088cc; }

.fa-facebook {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-facebook:hover {
    color: #3d5b99; }

.fa-twitter {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-twitter:hover {
    color: #00aced; }

.fa-instagram {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-instagram:hover {
    color: #eb8231; }

.fa-youtube {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-youtube:hover {
    color: #df6d17; }

.fa-linkedin {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-linkedin:hover {
    color: #0073a4; }

.fa-google-plus {
  -webkit-transition: .5s;
  transition: .5s; }
  .fa-google-plus:hover {
    color: #e25714; }

.social-vertical {
  height: 20px; }

.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.header-right_top {
  position: relative;
  display: flex; }

.telegram-wrapper {
  position: relative; }
  .telegram-wrapper:hover {
    cursor: pointer; }

.telegram-button:after {
  display: block; }

.telegram-button:after {
  display: none; }

.telegram-wrapper.opened .telegram-button:before {
  display: none; }

.telegram-wrapper.opened .telegram-button:after {
  display: block; }

.telegram-wrapper.opened .telegram-button {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14); }

.telegram-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
  color: #acacac;
  height: 35px;
  width: 35px;
  z-index: 102; }
  .telegram-button:before {
    content: '';
    border-right: 1px solid;
    position: absolute;
    height: 75%;
    right: -1px;
    width: 1px; }
  .telegram-button:after {
    content: '';
    position: absolute;
    background: #fff;
    height: 15px;
    width: 100%;
    top: 100%;
    left: 0; }

.telegram-list {
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  max-width: 300px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 101;
  margin: 0;
  top: 100%;
  right: 0; }
  @media all and (max-width: 768px) {
    .telegram-list {
      bottom: 100%;
      left: 0;
      top: auto;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      max-width: 200px; }
      .telegram-list li {
        text-align: left; } }
  .telegram-list li {
    border-bottom: 1px solid #eee;
    padding: 16px 14px;
    font-size: 14px; }
    .telegram-list li:hover {
      text-decoration: underline;
      color: #df6d17; }
    .telegram-list li a {
      display: block; }

.language-menu.opened {
  -webkit-transition: box-shadow .3s ease-in-out .15s;
  transition: box-shadow .3s ease-in-out .15s;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14); }

.language-menu {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  will-change: box-shadow;
  position: absolute;
  width: 75px;
  z-index: 1001;
  margin: 0;
  right: 0;
  top: 0; }
  .language-menu:hover {
    cursor: pointer; }

.language-menu_item {
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  will-change: transform;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  display: flex;
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  padding: 0 10px;
  height: 35px;
  width: 100%;
  top: 0; }
  .language-menu_item:hover {
    cursor: pointer; }
    .language-menu_item:hover .lm_item_text {
      text-decoration: underline;
      color: #df6d17; }

ul > li.no-underline:hover {
  text-decoration: none; }

.language-menu-icon {
  max-width: 100%;
  height: 20px;
  width: 20px; }

.lm_item_text {
  font-size: 14px;
  margin-left: 5px; }

.header-block {
  padding-top: 10px; }

.nav-top {
  border-top: 1px solid #ededed;
  display: flex;
  margin: 0; }
  .nav-top li {
    font-size: 14px;
    color: #444342; }
    .nav-top li:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #df6d17; }
  .nav-top a {
    color: #444342;
    font-size: 14px;
    display: block;
    padding: 15px; }
  .nav-top .active-nav {
    background: #f4f4f4; }

.search-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 245px;
  margin: 5px 0;
  width: 100%; }

.search-block button {
  background: url(/static/media/search.788abbc7.svg) 50% no-repeat #acacac;
  background-size: 60%;
  border-radius: 3px;
  height: 24px;
  width: 25px; }
  .search-block button a {
    display: block;
    height: 100%;
    width: 100%; }

.search-block input {
  background: #f4f4f4;
  border-radius: 3px 0 0 3px;
  padding: 3px 10px;
  font-size: 14px; }

.header-nav {
  position: relative; }

.nav-bot {
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  position: absolute;
  flex-wrap: wrap;
  z-index: 101;
  width: 100%;
  margin: 0; }
  .nav-bot li {
    font-size: 14px;
    padding: 15px 30px; }
    .nav-bot li:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #df6d17; }

.top-header {
  display: flex;
  justify-content: space-between; }

.home-breadcrumb img {
  margin-right: 10px; }

.line-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .line-breadcrumb span {
    margin-right: 10px; }
  .line-breadcrumb a {
    display: block; }
    .line-breadcrumb a > div {
      display: flex;
      align-items: center; }

.breadcrumb-wrapper {
  padding: 30px 0 10px; }

@media all and (max-width: 1080px) {
  .slider-carousel .slick-slider .slide-title_text, .slider-carousel .slick-slider .slide-title_subtext {
    font-size: 24px;
    line-height: 1.2;
    width: 85%; } }

@media all and (max-width: 768px) {
  .header-nav {
    display: none; }
  .opened-nav .mobile-top-header {
    padding-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
    max-width: 540px; } }

.ScrollUpButtonContainer {
  position: fixed;
  right: -100px;
  bottom: 50px;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
  cursor: pointer;
  background-color: unset;
  font-size: 20px;
  padding: 10px;
  z-index: 1000; }

.ScrollUpButtonContainer div {
  height: 58px;
  width: 58px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 29px;
  display: flex;
  justify-content: center;
  align-items: center; }

.ScrollUpButtonContainer div:hover {
  background: rgba(223, 109, 23, 0.6); }
  @media all and (max-width: 440px) {
    .ScrollUpButtonContainer div:hover {
      background: rgba(0, 0, 0, 0.2); } }

.ScrollUpButtonContainer div .arrow {
  background: none;
  border-radius: 0;
  height: 20px;
  width: 20px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-top: 5px; }

.ScrollUpButtonForTransition {
  right: 20px; }

.mobile-header-right {
  display: flex;
  justify-content: space-between; }

.mobile-logo-level {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.mobile-open-close-image {
  width: 40px;
  height: 40px;
  margin-left: 20px; }
  .mobile-open-close-image:hover {
    cursor: pointer; }

.mobile-search-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 245px;
  margin: 5px 0;
  width: 70%; }

.mobile-search-block button {
  background: url(/static/media/search.788abbc7.svg) 50% no-repeat #acacac;
  background-size: 60%;
  border-radius: 3px;
  height: 24px;
  width: 25px; }

.mobile-search-block input {
  background: #f4f4f4;
  border-radius: 3px 0 0 3px;
  padding: 3px 10px;
  font-size: 14px; }

.mobile-nav-block {
  width: 100%;
  text-align: right;
  background: #f4f4f4;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  left: 0; }
  .mobile-nav-block .mobile-nav-bottom {
    display: flex;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    background: #fff;
    position: fixed;
    height: 55px;
    width: 100%;
    bottom: 0; }

.mobile-main-nav {
  overflow: auto; }
  .mobile-main-nav .mob-nav-itm:not(:last-child) {
    border-bottom: .3px solid #dedede; }
  .mobile-main-nav .mob-nav-itm {
    padding: 12px 15px 12px 0; }
    .mobile-main-nav .mob-nav-itm a {
      font-size: 16px;
      text-align: right;
      color: #444342; }
  .mobile-main-nav .mob-sub-nav-itm {
    padding: 6px 0 6px 0; }
  .mobile-main-nav .mob-sub-nav-itm a {
    font-size: 12px;
    line-height: 1.5;
    text-align: right;
    color: #444342; }

.opened-nav {
  position: fixed;
  z-index: 1001;
  background: #fff;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 10px 15px 0 15px; }

.sub-item-rotate {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-right: 10px; }

.error-page_wrapper {
  min-height: 400px;
  max-width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px auto 120px;
  width: 100%; }

.error-page_title {
  font-size: 200px;
  font-weight: 600;
  color: #444342; }

.error-page_text {
  font-size: 38px;
  font-weight: 600;
  color: #444342;
  text-align: center;
  margin-bottom: 20px; }

.error-page_additional {
  font-size: 22px;
  font-weight: 500;
  color: #444342;
  text-align: left;
  margin-top: 20px; }

.error-page_link {
  font-size: 22px;
  font-weight: 500;
  color: #df6d17;
  text-align: center; }
  .error-page_link:hover {
    text-decoration: underline;
    cursor: pointer; }

.error-text-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; }

.error-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  flex: 1 1; }

.error-page_icon {
  margin: 0 auto;
  width: 60%; }

.error-text-wrapper.localization-error {
  align-items: flex-start;
  flex: 1.7 1; }
  .error-text-wrapper.localization-error .error-page_text,
  .error-text-wrapper.localization-error .error-page_link {
    text-align: left; }

@media screen and (max-width: 1080px) {
  .error-page_wrapper {
    flex-direction: column-reverse;
    margin: 40px auto 60px;
    flex-wrap: wrap; }
  .error-text-wrapper.localization-error {
    align-items: center;
    margin-top: 10px; }
    .error-text-wrapper.localization-error .error-page_text,
    .error-text-wrapper.localization-error .error-page_link {
      text-align: center; }
    .error-text-wrapper.localization-error .error-page_additional {
      text-align: center; } }

@media screen and (max-width: 768px) {
  .error-page_wrapper {
    max-width: 100%; }
  .error-page_title {
    font-size: 100px; }
  .error-page_text {
    font-size: 28px; }
  .error-page_link {
    font-size: 18px; } }

@media screen and (max-width: 1280px) {
  .App-service .services-item_wrapper {
    margin-bottom: 25px;
    width: 20%; }
  .main-content-block .news-content-block .last-news-item {
    display: flex;
    justify-content: center; }
  .main-content-block .news-content-block .last-news-item_big {
    width: 50%; }
  .main-content-block .news-content-block .last-news-item_small {
    width: 25%; } }

@media screen and (max-width: 1080px) {
  .main-content-block .news-content-block {
    justify-content: space-between; }
    .main-content-block .news-content-block .last-news-item {
      display: flex;
      justify-content: center; }
    .main-content-block .news-content-block .last-news-item_big {
      width: 49%; }
    .main-content-block .news-content-block .last-news-item_small {
      width: 25%; } }

@media screen and (max-width: 840px) {
  .home-page .App-service {
    justify-content: center; }
    .home-page .App-service .services-item_wrapper {
      width: 35%; }
  .main-content-block .news-content-block .last-news-item {
    display: flex;
    justify-content: center; }
  .main-content-block .news-content-block .last-news-item_big {
    width: 49%; }
  .main-content-block .news-content-block .last-news-item_small {
    width: 24%; } }

@media screen and (max-width: 768px) {
  .mobile-top-header {
    padding-bottom: 10px; }
  .main-slider .slick-slider {
    height: 445px; }
    .main-slider .slick-slider .slick-slide {
      display: flex;
      align-items: stretch;
      overflow: hidden; }
      .main-slider .slick-slider .slick-slide img {
        position: absolute;
        max-width: none;
        max-width: initial;
        height: 100%;
        width: auto;
        bottom: 0;
        top: 0; }
  .home-page .App-service {
    justify-content: space-around; }
    .home-page .App-service .services-item_wrapper {
      width: 40%;
      padding: 0; }
    .home-page .App-service .services-item {
      max-height: 185px;
      padding-bottom: 25px; }
      .home-page .App-service .services-item .services-text {
        font-size: 12px;
        margin-top: 20px;
        padding: 0;
        height: auto; }
  .home-page .secondary-slider.last-news-slider {
    height: 527px; }
    .home-page .secondary-slider.last-news-slider .slick-dots,
    .home-page .secondary-slider.last-news-slider .slick-arrow {
      bottom: -60px; }
  .home-page .cutted-hr {
    width: 100%; }
  .home-page .news-double-item {
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 18px; }
  .home-page .big-card {
    max-width: 100%; }
  .home-page .last-news-slider .slick-track {
    align-items: flex-start; }
  .home-page .propositions-item {
    margin: 0 auto; }
  .home-page .main-content-block {
    padding: 10px 0 30px; }
    .home-page .main-content-block .news-content-block .last-news-item {
      display: flex;
      justify-content: center; }
    .home-page .main-content-block .news-content-block .last-news-item_big {
      width: 49%; }
    .home-page .main-content-block .news-content-block .last-news-item_small {
      width: 24%; }
    .home-page .main-content-block .secondary-slider {
      margin-left: 0;
      padding-left: 0; }
    .home-page .main-content-block .partners-wrapper .page-title,
    .home-page .main-content-block .propositions-wrapper .page-title {
      padding: 20px 0;
      font-size: 22px; } }

.news-item {
  max-width: 208px;
  min-height: 380px;
  margin: 8px 0;
  width: 100%;
  display: flex; }

.empty-text {
  font-size: 16px;
  padding: 15px; }

.news-double-item {
  max-width: 430px;
  max-height: 438px;
  margin: 9px 0;
  width: 100%; }

.side-news-block {
  padding-top: 10px;
  width: 100%;
  flex: 1 1; }
  .side-news-block .side-news-wrapper {
    width: 100%; }

.side-news-block.fixed .side-news-wrapper {
  position: fixed;
  top: 30px; }

.side-news-block.bottom {
  position: absolute;
  max-width: 208px;
  bottom: 30px;
  right: 5px; }
  .side-news-block.bottom .side-news-wrapper {
    display: flex;
    flex-direction: column;
    position: static;
    position: initial;
    flex-wrap: wrap; }

.side-text_wr {
  background: rgba(255, 255, 255, 0.5);
  padding: 8px 15px 10px 15px;
  -webkit-transition: all .3s;
  transition: all .3s;
  position: absolute;
  font-weight: 600;
  line-height: 1.4;
  font-size: 14px;
  color: #202124;
  bottom: -100%;
  height: 72px;
  width: 100%; }
  .side-text_wr:hover {
    text-decoration: underline;
    cursor: pointer; }

.side-news-item {
  display: flex;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15), 0 15px 25px 0 rgba(0, 0, 0, 0.05);
  justify-content: center;
  align-items: center;
  background: #dadada;
  min-height: 208px;
  max-width: 208px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden; }
  .side-news-item:hover {
    box-shadow: 0 15px 25px 0 rgba(223, 109, 23, 0.25);
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }
    .side-news-item:hover .side-text_wr {
      bottom: 0; }
  .side-news-item a:hover {
    text-decoration: underline; }

.side-news-image {
  background: #ddd;
  position: absolute;
  width: 100%; }

.news-content-block {
  display: flex;
  flex-wrap: wrap;
  width: 81%; }
  .news-content-block > .last-news-item, .news-content-block .row {
    width: 100%;
    padding: 0; }

.row.no-gutter {
  margin-left: 0;
  margin-right: 0; }

.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
  padding-right: 0;
  padding-left: 0; }

.main-content-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0 50px; }
  .main-content-block > div:last-child {
    width: 20%; }

@media screen and (max-width: 1226px) {
  .main-content-block > div:last-child {
    display: none; } }

.news-time-block {
  font-size: 12px;
  color: #444342;
  margin-left: 15px; }
  .news-time-block span {
    font-size: 12px;
    font-weight: 900;
    margin-right: 10px; }

.tags-share-wrapper {
  display: flex;
  align-items: center;
  margin: 0 10px 0 0; }

.share-wrapper {
  position: relative;
  height: 30px;
  width: 30px; }

.share-list {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  will-change: box-shadow;
  position: absolute;
  font-size: 18px;
  color: #acacac;
  height: 30px;
  width: 30px;
  margin: 0;
  bottom: 0;
  left: 0; }

.share-list-down {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  will-change: box-shadow;
  position: absolute;
  font-size: 18px;
  color: #acacac;
  height: 30px;
  width: 30px;
  margin: 0;
  right: 0;
  top: 7px; }
  @media all and (max-width: 768px) {
    .share-list-down {
      top: 12px; } }
  .share-list-down:hover {
    cursor: pointer; }
  .share-list-down .share-item {
    bottom: auto;
    bottom: initial;
    left: auto;
    left: initial;
    right: 0;
    top: 0; }

.share-list.opened,
.share-list-down.opened {
  -webkit-transition: box-shadow .3s ease-in-out .15s;
  transition: box-shadow .3s ease-in-out .15s;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14); }

.share-item {
  display: flex;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  will-change: transform;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  justify-content: center;
  align-items: center;
  background: #fff;
  position: absolute;
  height: 30px;
  width: 100%;
  bottom: 0;
  left: 0; }

.news-bottom {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.big-name {
  width: 771px;
  height: 58px;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 35px;
  color: #202124; }

.part-marg, .marg {
  min-height: 300px; }

.cutted-hr {
  margin-left: 0;
  width: 98%; }

.news-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .news-wrapper .news-page-item {
    padding: 0; }

.accordion-wrapper .accordion-button {
  display: flex;
  align-items: center;
  padding: 10px 0; }

.accordion-wrapper img {
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-left: 15px; }

.accordion-wrapper.opened img {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
  margin: 10px 0; }
  .pagination-wrapper .accordion-wrapper .accordion-head {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0);
    background: #f6f6f6;
    border-radius: 5px;
    padding: 0 15px; }
  .pagination-wrapper .accordion-wrapper .accordion-body {
    top: calc(100% + 1px);
    padding: 10px 0;
    width: auto; }
    .pagination-wrapper .accordion-wrapper .accordion-body ul {
      width: 100%;
      margin: 0; }
      .pagination-wrapper .accordion-wrapper .accordion-body ul li {
        padding: 10px 15px; }
        .pagination-wrapper .accordion-wrapper .accordion-body ul li:hover {
          text-decoration: underline;
          cursor: pointer;
          color: #df6d17; }
  .pagination-wrapper .accordion-wrapper.opened .accordion-head {
    border-radius: 5px 5px 0 0;
    background: #fff; }
  .pagination-wrapper .accordion-wrapper.opened .accordion-body {
    border-radius: 0 0 5px 5px;
    min-width: 191px; }

.pagination-list {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative; }
  .pagination-list ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 5px; }
    .pagination-list ul li {
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      margin: 2px; }
      @media all and (max-width: 440px) {
        .pagination-list ul li {
          height: 25px;
          width: 25px;
          font-size: 12px; } }
      .pagination-list ul li.active {
        background: #df6d17;
        cursor: default !important;
        color: #fff; }
      .pagination-list ul li:hover {
        cursor: pointer;
        border: 1px solid #df6d17; }
  .pagination-list .slick-arrow {
    background: url(/static/media/arrow-grey.044b6027.svg) 50% no-repeat;
    background-size: 5px;
    position: relative;
    font-size: 0;
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    z-index: 10; }
    .pagination-list .slick-arrow:hover {
      background: url(/static/media/arrow-hover.68145449.svg) 50% no-repeat;
      background-size: 5px;
      cursor: pointer; }
  .pagination-list .slick-prev:hover:after {
    content: '';
    border-radius: 0 30px 30px 0;
    border-bottom: 1px solid #5e5e5e;
    border-right: 1px solid #5e5e5e;
    border-top: 1px solid #5e5e5e;
    position: absolute;
    height: calc(100% - 2px);
    border-left: none;
    width: 50%;
    right: 0;
    top: 1px; }
  .pagination-list .slick-prev:hover:before {
    border-radius: 30px 0 0 30px;
    border-bottom: 3px solid #df6d17;
    border-left: 3px solid #df6d17;
    border-top: 3px solid #df6d17;
    width: 50%; }
  .pagination-list .slick-next {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .pagination-list .slick-next:hover:after {
      content: '';
      border-radius: 0 30px 30px 0;
      border-bottom: 1px solid #5e5e5e;
      border-right: 1px solid #5e5e5e;
      border-top: 1px solid #5e5e5e;
      position: absolute;
      height: calc(100% - 2px);
      border-left: none;
      width: 50%;
      right: 0;
      top: 1px; }
    .pagination-list .slick-next:hover:before {
      border-radius: 30px 0 0 30px;
      border-bottom: 3px solid #df6d17;
      border-left: 3px solid #df6d17;
      border-top: 3px solid #df6d17;
      width: 50%;
      left: 1px; }
  .pagination-list .slick-prev:after,
  .pagination-list .slick-next:after {
    content: '';
    border: 1px solid #5e5e5e;
    border-radius: 50%;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    right: 0;
    top: 1px; }
  .pagination-list .slick-prev:before,
  .pagination-list .slick-next:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0; }

.selected-filters_wrapper {
  display: flex;
  border-top: 1px solid #ededed;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05);
  align-items: center;
  margin: 0 19px 10px 0; }

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;
  margin: 10px 0; }

.selected-filters_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #444342;
  border-radius: 10px;
  margin: 0 5px 5px 0;
  padding: 3px 8px;
  font-size: 14px; }

.filter-cross {
  border: 1px solid #444342;
  border-radius: 50%;
  margin-left: 5px;
  height: 14px;
  width: 14px; }
  .filter-cross:hover {
    border: 1px solid #f0001d;
    cursor: pointer; }
    .filter-cross:hover:after, .filter-cross:hover:before {
      background: #f0001d; }

.filter-cross_big {
  border: 1px solid #444342;
  margin: 0 5px 5px 0;
  border-radius: 50%;
  height: 20px;
  width: 20px; }
  .filter-cross_big:hover {
    border: 1px solid #f0001d;
    cursor: pointer; }
    .filter-cross_big:hover:after, .filter-cross_big:hover:before {
      background: #f0001d; }

.filter-cross,
.filter-cross_big {
  display: flex;
  align-items: center;
  justify-content: center; }

.filter-cross:before,
.filter-cross_big:before {
  content: '';
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  background: #000;
  height: 1px;
  width: 12px; }

.filter-cross:after,
.filter-cross_big:after {
  content: '';
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  background: #000;
  height: 1px;
  width: 12px; }

.selected-filters .filter-cross:after,
.selected-filters .filter-cross:before {
  width: 8px; }

.accordion-wrapper .sort-text_wr {
  display: flex;
  align-items: center;
  font-size: 14px; }
  .accordion-wrapper .sort-text_wr .sort-image {
    -webkit-transform: none;
            transform: none;
    margin: 0 10px 0 0; }

.pagination-search {
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center; }

.search-item_wrapper {
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  background: #fff; }

.search-item_title {
  text-decoration: underline;
  font-weight: 600;
  font-size: 22px;
  color: #202124; }
  .search-item_title:hover {
    cursor: pointer;
    color: #df6d17; }

.search-item_text {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: .5px;
  color: #444342; }

.search-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 20px 15px 0; }

.search-item_wrapper {
  margin-bottom: 15px;
  padding: 15px; }

.news-item-title_wrapper {
  display: flex;
  justify-content: space-between; }

.search-filters_wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  width: 100%; }

.search-filters_list {
  display: flex;
  flex-wrap: wrap; }

.filters-search_title {
  font-size: 14px;
  font-weight: 500;
  color: #444342; }

.accordion-button p {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #444342; }

.search-filters_item {
  font-size: 14px;
  font-weight: 500;
  color: #444342;
  margin-top: 10px;
  max-width: 25%;
  width: 25%; }
  .search-filters_item label {
    display: flex;
    padding-right: 10px; }

@media screen and (max-width: 768px) {
  .news-double-item {
    border: 1px solid whitesmoke; }
  .news-page .accordion-wrapper {
    padding-right: 0; }
  .news-page .selected-filters_wrapper {
    margin: 0 0 10px 0;
    border: none; }
  .news-page .accordion-head {
    flex-direction: column; }
  .news-page .accordion-wrapper .accordion-head {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 15px; }
  .news-page .pagination-wrapper {
    padding: 0;
    flex-direction: column; }
  .news-page .accordion-wrapper {
    width: 100%; }
  .news-page .page-title {
    font-size: 28px; }
  .news-page .main-content-block {
    padding: 20px 0 50px; }
  .news-page .big-news-tag-block {
    display: none; }
  .news-page .accordion-wrapper.opened .accordion-body {
    border-radius: 0 0 5px 5px;
    min-width: 190px;
    width: 100%; }
  .news-page .accordion-wrapper .accordion-body {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .news-page .accordion-wrapper .accordion-head {
    margin: 0; }
  .news-page .pagination-list {
    margin-top: 20px; }
  .news-page .datepicker-wrapper {
    border-right: none; }
  .news-page .filters-item {
    width: 50%; }
  .news-page .news-double-item, .news-page .big-card {
    max-width: 100%; } }

.services-item {
  display: block;
  flex-direction: column;
  background: #fff;
  height: 220px;
  text-align: center;
  padding-top: 25px;
  will-change: box-shadow; }
  .services-item:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, 0.3);
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }

.services {
  display: flex;
  justify-content: space-between; }

.services-image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%; }

.services-image {
  max-width: 100px;
  width: 100%; }

.services-text {
  height: 55px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-align: center;
  color: #5e5e5e;
  margin-top: 28px;
  padding: 0 10px;
  overflow: hidden; }

.slick-track {
  display: flex;
  align-items: center;
  overflow: hidden; }

.editions-page .slick-track {
  margin-left: 0; }

.slider-carousel .slick-slider {
  background: #ddd;
  overflow: hidden; }
  .slider-carousel .slick-slider .slick-list,
  .slider-carousel .slick-slider .slick-track {
    height: 445px; }

.slider-carousel .slick-slide {
  display: flex; }

.slider-carousel .slick-slide > div {
  text-align: center;
  display: flex;
  width: 100%; }

.slider-carousel .item-wrapper {
  position: relative;
  display: inline-flex !important;
  align-items: center; }

.slider-carousel .slick-slide a,
.slider-carousel .slick-slide img {
  max-width: 100%;
  margin: 0 auto;
  width: 100%; }

.slider-carousel .slide-title_wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  max-width: 1440px;
  bottom: 135px;
  width: 100%; }

.slider-carousel .slick-current .slide-title_bg {
  -webkit-transition: opacity .3s ease-in .3s;
  transition: opacity .3s ease-in .3s;
  opacity: .8; }

.slider-carousel .slide-title_bg {
  background: -webkit-linear-gradient(351deg, #fff, rgba(255, 255, 255, 0));
  background: linear-gradient(99deg, #fff, rgba(255, 255, 255, 0));
  will-change: opacity;
  position: absolute;
  height: 100%;
  width: 80%;
  opacity: 0;
  bottom: 0;
  top: 0; }

.slider-carousel .slide-title_text,
.slider-carousel .slide-title_subtext {
  position: relative;
  font-weight: bold;
  line-height: 1.39;
  text-align: left;
  font-size: 36px;
  color: #fff;
  width: 65%; }

.slider-carousel .slide-title_subtext {
  font-size: 18px; }

.slider-carousel .slick-arrow {
  background: url(/static/media/arrow.6997b175.svg) 50% no-repeat;
  background-size: 8px;
  width: 50px;
  position: absolute;
  font-size: 0;
  height: 50px;
  bottom: 45px;
  z-index: 10; }
  .slider-carousel .slick-arrow:hover {
    background: url(/static/media/arrow-hover.68145449.svg) 50% no-repeat;
    background-size: 8px; }

.slider-carousel .slick-prev {
  margin-left: 15px;
  left: calc(50% - 570px); }
  .slider-carousel .slick-prev:hover:after {
    content: '';
    border-radius: 0 50px 50px 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    position: absolute;
    height: calc(100% - 2px);
    border-left: none;
    width: 50%;
    right: 0;
    top: 1px; }
  .slider-carousel .slick-prev:hover:before {
    border-radius: 50px 0 0 50px;
    border-bottom: 3px solid #df6d17;
    border-left: 3px solid #df6d17;
    border-top: 3px solid #df6d17;
    width: 50%; }

.slider-carousel .slick-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 115px;
  left: calc(50% - 570px); }
  .slider-carousel .slick-next:hover:after {
    content: '';
    border-radius: 0 50px 50px 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    position: absolute;
    height: calc(100% - 2px);
    border-left: none;
    width: 50%;
    right: 0;
    top: 1px; }
  .slider-carousel .slick-next:hover:before {
    border-radius: 50px 0 0 50px;
    border-bottom: 3px solid #df6d17;
    border-left: 3px solid #df6d17;
    border-top: 3px solid #df6d17;
    width: 50%; }

.slider-carousel .slick-prev:after,
.slider-carousel .slick-next:after {
  content: '';
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  top: 1px;
  right: 0; }

.slider-carousel .slick-prev:before,
.slider-carousel .slick-next:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

.slider-carousel .slick-dots {
  display: flex !important;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  right: calc(50% - 570px);
  margin-right: 15px;
  bottom: 0;
  top: 0; }
  .slider-carousel .slick-dots li {
    position: relative;
    margin: 5px 0;
    font-size: 0;
    height: 15px;
    width: 15px; }
    .slider-carousel .slick-dots li:hover {
      border: 1px solid #df6d17;
      border-radius: 50%;
      cursor: pointer; }
    .slider-carousel .slick-dots li:after {
      content: '';
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      position: absolute;
      overflow: hidden;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0; }
    .slider-carousel .slick-dots li button {
      position: relative;
      z-index: 10;
      height: 100%;
      width: 100%; }
  .slider-carousel .slick-dots .slick-active:after {
    background: #df6d17; }

.slider-carousel {
  max-width: 1440px;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  width: 100%; }

.slider-gradient {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.55)), color-stop(68%, rgba(0, 0, 0, 0.45)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.45) 68%, rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.45) 68%, rgba(0, 0, 0, 0));
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

@media all and (max-width: 1200px) {
  .slider-carousel .slick-next {
    left: 5%; }
  .slider-carousel .slick-prev {
    left: 5%; } }

@media all and (max-width: 1080px) {
  .slider-carousel .slide-title_bg {
    width: 100%; }
  .slider-carousel .slick-dots {
    right: 5%; }
  .slider-carousel.main-slider .slide-title_text,
  .slider-carousel.main-slider .slide-title_subtext {
    line-height: 1.4;
    font-size: 24px; }
  .slider-carousel .slick-next {
    margin-left: 85px;
    left: 10%; }
  .slider-carousel .slick-prev {
    left: 10%; } }

@media all and (max-width: 768px) {
  .slider-carousel.main-slider .slide-title_text,
  .slider-carousel.main-slider .slide-title_subtext {
    line-height: 1.2;
    font-size: 24px; }
  .slider-carousel .slick-next {
    margin-left: 85px;
    left: 10%; }
  .slider-carousel .slick-prev {
    left: 10%; } }

@media all and (max-width: 576px) {
  .slider-carousel .slick-next {
    left: 15px; }
  .slider-carousel .slick-prev {
    left: 15px; } }

.big-news-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 248px;
  width: 100%; }
  .big-news-image img {
    width: 100%; }

.truncate-wrapper,
.big-news-title > a {
  display: block; }

.big-news-title {
  padding: 10px 15px 10px 15px;
  color: #202124;
  width: 100%;
  overflow: hidden; }
  .big-news-title h3 {
    max-height: 77px;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
    line-height: normal; }

.big-card > a {
  display: block;
  height: 394px;
  overflow: hidden;
  width: 100%; }

.big-card {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  max-width: 432px;
  height: 438px; }
  .big-card:hover {
    box-shadow: 0 15px 25px 0 rgba(223, 109, 23, 0.25);
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }

.big-news-description {
  line-height: 1.43;
  margin: 8px 15px 0 15px;
  font-size: 14px;
  color: #444342;
  max-height: 98px;
  overflow: hidden; }
  .big-news-description p {
    overflow: hidden; }

.big-news-tag-block {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 10px 10px 10px;
  height: 25px; }
  .big-news-tag-block span {
    padding-bottom: 5px;
    font-size: 13px; }

.tag-item {
  border: 1px solid transparent;
  background: #e9e9e9;
  border-radius: 9px;
  margin: 0 5px 5px 0;
  padding: 1px 10px;
  font-size: 12px;
  height: 18px;
  color: #000;
  line-height: 14px;
  overflow: hidden;
  flex-shrink: 0; }

.small-news-image {
  max-height: 116px;
  width: 100%; }

.small-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
  position: relative;
  background: #fff;
  max-width: 208px;
  height: auto;
  width: 100%; }
  .small-card:hover {
    box-shadow: 0 15px 25px 0 rgba(223, 109, 23, 0.25);
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }
  .small-card .news-bottom {
    top: 10px; }
  .small-card .book-image_wrapper {
    background: #ddd;
    height: 290px;
    position: relative; }
  .small-card a {
    display: flex;
    flex-direction: column; }

.small-news-title {
  margin: 15px 15px 0 15px;
  line-height: 1.57;
  font-weight: 600;
  font-size: 14px;
  color: #202124;
  height: 45px; }
  .small-news-title:hover * {
    cursor: pointer; }

.small-news-description {
  margin: 15px 15px 0 15px;
  height: 100px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: .5px;
  color: #444342;
  overflow: hidden; }

.compact-tags {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/static/media/tag.b070d5aa.svg) 50% no-repeat;
  background-size: contain;
  font-size: 12px;
  color: #444342;
  height: 20px;
  width: 30px; }

.currency-editions {
  position: absolute;
  bottom: 104px;
  left: 0;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background: #df6d17;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding: 3px 15px; }

.home-page .secondary-slider {
  height: 300px; }

.secondary-slider_empty {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;
  width: 100%; }

.editions-page .secondary-slider .slick-slide > div {
  max-height: 390px; }

@media all and (max-width: 440px) {
  .editions-page .secondary-slider .slick-next {
    right: calc(50% - 60px); } }

@media all and (max-width: 440px) {
  .editions-page .secondary-slider .slick-prev {
    left: calc(50% - 60px); } }

.news-item-related .secondary-slider .slick-slide > div {
  padding-right: 15px;
  height: 375px; }

.secondary-slider {
  overflow: hidden;
  margin-left: -20px;
  padding-left: 20px; }
  .secondary-slider .slick-slider {
    width: 100%; }
  .secondary-slider .slick-list {
    overflow: visible; }
  .secondary-slider .slick-slide > div {
    background: #fff;
    text-align: left; }
  .secondary-slider .slick-arrow {
    background: url(/static/media/arrow-grey.044b6027.svg) 50% no-repeat;
    background-size: 8px;
    position: absolute;
    font-size: 0;
    height: 50px;
    width: 50px;
    bottom: -67px;
    z-index: 10; }
    .secondary-slider .slick-arrow:hover {
      background: url(/static/media/arrow-hover.68145449.svg) 50% no-repeat;
      background-size: 8px; }
  .secondary-slider .slick-prev {
    left: calc(50% - 140px); }
    .secondary-slider .slick-prev:hover:after {
      content: '';
      border-radius: 0 50px 50px 0;
      border-bottom: 1px solid #5e5e5e;
      border-right: 1px solid #5e5e5e;
      border-top: 1px solid #5e5e5e;
      position: absolute;
      height: calc(100% - 2px);
      border-left: none;
      width: 50%;
      right: 0;
      top: 1px; }
    .secondary-slider .slick-prev:hover:before {
      border-radius: 50px 0 0 50px;
      border-bottom: 3px solid #df6d17;
      border-left: 3px solid #df6d17;
      border-top: 3px solid #df6d17;
      width: 50%; }
  .secondary-slider .slick-next {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    right: calc(50% - 140px); }
    .secondary-slider .slick-next:hover:after {
      content: '';
      border-radius: 0 50px 50px 0;
      border-bottom: 1px solid #5e5e5e;
      border-right: 1px solid #5e5e5e;
      border-top: 1px solid #5e5e5e;
      position: absolute;
      height: calc(100% - 2px);
      border-left: none;
      width: 50%;
      right: 0;
      top: 1px; }
    .secondary-slider .slick-next:hover:before {
      border-radius: 50px 0 0 50px;
      border-bottom: 3px solid #df6d17;
      border-left: 3px solid #df6d17;
      border-top: 3px solid #df6d17;
      width: 50%;
      left: 1px; }
  .secondary-slider .slick-prev:after,
  .secondary-slider .slick-next:after {
    content: '';
    border: 1px solid #5e5e5e;
    border-radius: 50%;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    top: 1px;
    right: 0; }
  .secondary-slider .slick-prev:before,
  .secondary-slider .slick-next:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0; }
  .secondary-slider .slick-dots {
    display: flex !important;
    position: absolute;
    justify-content: center;
    bottom: -70px;
    right: 0;
    left: 0; }
    .secondary-slider .slick-dots li {
      position: relative;
      margin: 5px;
      font-size: 0;
      height: 15px;
      width: 15px; }
      .secondary-slider .slick-dots li:hover {
        border: 1px solid #df6d17;
        border-radius: 50%;
        cursor: pointer; }
      .secondary-slider .slick-dots li:after {
        content: '';
        background: #5e5e5e;
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0; }
      .secondary-slider .slick-dots li button {
        position: relative;
        z-index: 10;
        height: 100%;
        width: 100%; }
    .secondary-slider .slick-dots .slick-active:after {
      background: #df6d17; }

@media all and (max-width: 440px) {
  .secondary-slider .slick-slide > div {
    display: flex;
    justify-content: center; } }

.partners-wrapper,
.propositions-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }
  .partners-wrapper .page-title,
  .propositions-wrapper .page-title {
    padding: 30px 0;
    text-align: center; }
  .partners-wrapper.iblocks,
  .propositions-wrapper.iblocks {
    margin-bottom: 20px; }
    .partners-wrapper.iblocks h3,
    .propositions-wrapper.iblocks h3 {
      font-size: 22px;
      font-weight: 700;
      color: #666;
      margin: 10px 0 15px 0; }

.row.no-gutter.iblocks-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }

.propositions-wrapper.iblocks .secondary-slider .slick-slide > div {
  background: transparent;
  padding: 5px 5px 20px 5px; }

.propositions-wrapper.iblocks .secondary-slider {
  height: 420px; }
  .propositions-wrapper.iblocks .secondary-slider .slick-arrow {
    bottom: -50px; }
  .propositions-wrapper.iblocks .secondary-slider .slick-dots {
    bottom: -50px; }

.iblock-item-link {
  height: 325px;
  padding: 10px;
  width: 204px;
  display: block;
  overflow: hidden;
  color: #666; }
  .iblock-item-link.static {
    margin-right: 10px;
    margin-bottom: 30px; }
  .iblock-item-link:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, 0.3); }

.iblock-img-wrapper {
  width: 100%;
  height: 190px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }
  .iblock-img-wrapper img {
    width: 100%; }

.iblock-caption {
  text-align: center;
  width: 100%;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.36;
  overflow: hidden;
  max-height: 106px;
  color: #666; }

.propositions-item {
  background: #fff;
  height: 210px;
  padding: 10px;
  will-change: box-shadow;
  text-align: center;
  margin: auto;
  width: 90%; }
  .propositions-item:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, 0.3);
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }

.no-pointer {
  pointer-events: none; }

.propositions-wrapper .secondary-slider .slick-slide > div {
  background: transparent; }

.services {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.propositions-image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%; }

.propositions-image {
  margin: 0 auto;
  max-width: 120px;
  width: auto;
  max-height: 130px; }

.files-image {
  margin: 0 auto;
  max-width: 120px;
  width: auto;
  max-height: 130px; }

.propositions-text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  margin: 10px auto 0;
  text-align: center;
  color: #5e5e5e;
  width: 130px;
  overflow: hidden;
  height: 55px; }

@media screen and (max-width: 440px) {
  .iblocks .slick-arrow.slick-next {
    right: calc(50% - 70px); }
  .iblocks .slick-arrow.slick-prev {
    left: calc(50% - 70px); } }

@media screen and (max-width: 473px) {
  .iblock-item-link.static {
    max-width: 45%;
    margin-bottom: 10px; }
  .row.no-gutter.iblocks-row {
    justify-content: space-between; }
  .propositions-wrapper.iblocks {
    justify-content: space-between; } }

.accordion-button {
  padding: 20px 0; }

.filters-select-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 5 1; }

.datepicker-wrapper {
  border-right: 1px solid #ddd;
  padding: 25px 15px 15px 15px;
  flex: 1.8 1; }

.datepicker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.accordion-wrapper.opened .accordion-head {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15); }

.accordion-wrapper {
  position: relative; }
  .accordion-wrapper .accordion-head {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0);
    padding: 10px 15px;
    min-width: 190px; }
    .accordion-wrapper .accordion-head .search-block {
      max-width: 335px; }
      .accordion-wrapper .accordion-head .search-block .search-submit {
        background-size: 60%;
        height: 35px;
        width: 35px; }
      .accordion-wrapper .accordion-head .search-block input {
        height: 35px; }
  .accordion-wrapper .accordion-body {
    display: flex;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    width: calc(100% - 20px); }

.filters-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 160px;
  overflow-y: auto; }

.filters-item {
  display: flex;
  flex-wrap: wrap;
  width: 33%;
  padding: 10px 10px 10px 0; }
  .filters-item label {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .filters-item label:hover {
      cursor: pointer; }
    .filters-item label p {
      flex: 1 1; }
  .filters-item input:checked + .checkbox-facade {
    background: #df6d17;
    background-image: url(/static/media/CheckBox.5b533837.svg); }

.checkbox-wrapper {
  display: flex;
  margin-right: 10px; }

.checkbox-wrapper input {
  height: 0;
  width: 0; }

.checkbox-facade {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #e9e9e9; }

.divider {
  font-size: 24px;
  color: #acacac; }

.dp-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0; }
  .dp-inputs-wrapper input {
    border-radius: 6px;
    background: #f6f6f6;
    text-align: center;
    padding: 3px 10px;
    font-size: 14px;
    color: #444342;
    height: 35px;
    width: 45%;
    cursor: pointer; }
  .dp-inputs-wrapper input.active {
    background: #df6d17;
    color: #fff; }

.overflow {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

.periods-wrapper {
  border-bottom: 1px solid #ddd;
  padding: 25px 15px 15px 15px;
  /* width*/
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .periods-wrapper ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .periods-wrapper scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .periods-wrapper ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px; }
  .periods-wrapper ::-webkit-scrollbar-thumb {
    background-color: #444342;
    border-radius: 5px; }
  .periods-wrapper ::-webkit-scrollbar-thumb:hover {
    background: #858585; }

.tags-wrapper {
  /* width*/
  /* Track */
  /* Handle */
  /* Handle on hover */
  padding: 15px; }
  .tags-wrapper ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .tags-wrapper scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .tags-wrapper ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px; }
  .tags-wrapper ::-webkit-scrollbar-thumb {
    background-color: #444342;
    border-radius: 5px; }
  .tags-wrapper ::-webkit-scrollbar-thumb:hover {
    background: #858585; }

.news-detail .secondary-slider,
.news-detail .secondary-slider_empty {
  height: 282px; }

.news-detail .news-item-related .secondary-slider {
  height: 470px; }

.news-detail .news-item-related .secondary-slider_empty {
  height: auto; }

.news-detail .list-item_text {
  font-size: 14px;
  font-weight: 600;
  color: #202124; }

.news-item-title {
  font-size: 38px;
  font-weight: 600;
  line-height: 1.26;
  color: #333333; }

.news-separator {
  border-bottom: 1px solid grey; }

.news-item-wrapper {
  padding-right: 20px;
  width: 100%; }

.about-content p {
  overflow: inherit; }

.about-content figcaption {
  text-align: center;
  margin-top: 15px; }

.from-editor figcaption {
  text-align: center;
  margin-top: 15px; }

.from-editor p {
  overflow: inherit; }

.news-item-description {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #666; }
  .news-item-description.from-editor p {
    overflow: inherit; }
  .news-item-description.from-editor figcaption {
    text-align: center;
    margin-top: 15px; }
  .news-item-description.from-editor ul {
    list-style: disc;
    padding-left: 17px; }
  .news-item-description.from-editor ol {
    list-style: decimal;
    padding-left: 17px; }
  .news-item-description > img {
    max-width: 100%;
    width: 100%;
    padding: 10px 0; }
  .news-item-description a {
    color: #df6d17; }
    .news-item-description a:hover {
      text-decoration: underline; }
      .news-item-description a:hover strong {
        text-decoration: underline; }
  .news-item-description h2 {
    font-size: 22px;
    line-height: 1.5; }
  .news-item-description h3 {
    font-size: 18px;
    line-height: 1.5; }
  .news-item-description h4 {
    font-size: 16px;
    line-height: 1.5; }

.placeholder {
  width: 100%;
  min-height: 150px;
  margin: 10px 0; }

.news-item-text {
  line-height: 30px;
  font-size: 16px;
  margin: 10px 0;
  color: #666; }

.news-item-image {
  position: relative;
  width: 100%; }
  .news-item-image img {
    max-width: 100%;
    width: 100%; }

.news-item-image_description {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  width: 100%;
  bottom: 0; }

.bottom-date-wrapper {
  margin-left: auto;
  align-items: flex-end;
  width: 120px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 10px 0 5px; }
  .bottom-date-wrapper .share-news-wrapper:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0; }
  .bottom-date-wrapper .news-date {
    text-align: right; }

.tags {
  display: flex;
  margin-bottom: 20px; }

.news-section {
  width: 100%; }

.news-item {
  max-width: 205px;
  width: 100%; }

.list-wrapper {
  width: 100%;
  padding-right: 20px; }

.news-date > * {
  font-size: 12px; }

.list-item_wr {
  display: flex;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  position: relative;
  max-width: 205px;
  height: 205px;
  justify-content: space-between; }
  .list-item_wr:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, 0.3);
    cursor: pointer; }

.list-title {
  font-size: 22px;
  margin: 25px 0;
  font-weight: 600; }

.secondary-slider_empty > * {
  margin-bottom: 20px;
  padding: 0 .5%;
  width: 24%; }

.secondary-slider {
  position: relative;
  width: 100%; }

.list-item_text {
  background: #fff;
  position: relative;
  padding: 10px;
  z-index: 10; }

.secondary-slider:before {
  content: '';
  background: #fff;
  position: absolute;
  height: 100%;
  z-index: 10;
  width: 8px;
  left: 0;
  top: 0; }

.secondary-slider:after {
  content: '';
  background: #fff;
  position: absolute;
  height: 100%;
  z-index: 10;
  width: 7px;
  right: 0;
  top: 0; }

.attached-files {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  margin: 0;
  left: 0;
  top: 0; }

.attached-files li {
  font-size: 12px;
  padding: 5px 10px; }

.list-item_image {
  background: #ddd;
  position: relative;
  height: 150px; }

.share-news-wrapper {
  height: 55px;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 0 20px; }

.print {
  background: url(/static/media/print.dabd2faa.svg) 50% no-repeat;
  background-size: contain;
  margin-right: 35px;
  height: 25px;
  width: 25px; }
  .print:hover {
    cursor: pointer; }

.share {
  background: url(/static/media/share.7f254451.svg) 50% no-repeat;
  background-size: contain;
  margin-left: 15px;
  height: 25px;
  width: 25px; }
  .share:hover {
    cursor: pointer; }

.small-card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  overflow: hidden;
  height: 115px; }
  .small-card_wrapper img {
    max-width: 100%;
    width: 100%; }

.secondary-slider:after {
  display: none; }

.news-info-share_wrapper {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  @media screen and (max-width: 768px) {
    .news-info-share_wrapper {
      flex-direction: row;
      align-items: center; } }

@media screen and (max-width: 768px) {
  .news-item-title_wrapper {
    flex-direction: column; }
  .news-item-title_wrapper.page {
    flex-direction: row; }
  .news-item-title {
    margin-bottom: 25px;
    margin-top: 15px;
    font-size: 18px; }
  .secondary-slider {
    margin-left: 0;
    padding-left: 0; }
  .news-info-share_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .share-news-wrapper {
    padding: 0; }
  .news-item-description {
    font-size: 14px;
    line-height: 22px; }
  .share-news-wrapper {
    padding: 0; }
    .share-news-wrapper:after {
      display: none; }
  .secondary-slider:after,
  .secondary-slider:before {
    display: none; }
  .bottom-date-wrapper {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
  .list-item_wr {
    max-width: 100%; }
  .list-item_image {
    overflow: hidden; }
  .slick-slide img {
    max-width: 100%; }
  .modal-content .slick-arrow.slick-next {
    right: 10px; }
  .modal-content .slick-arrow.slick-prev {
    left: 10px; }
  .list-item_text {
    padding: 10px 10px 40px 10px;
    font-size: 20px;
    color: #202124; }
  .small-card {
    width: 100%;
    margin: 0 auto; }
  .small-news-description {
    overflow: hidden;
    margin: 15px;
    height: 65px; }
  .small-news-title {
    height: 45px;
    overflow: hidden; }
  .news-item-wrapper {
    padding-right: 0; }
  .news-detail .main-content-block {
    padding: 10px 0 50px; }
  .secondary-slider_empty > * {
    width: 50%; } }

@media screen and (max-width: 768px) {
  .share-news-wrapper.page-item {
    margin-left: auto; } }

.table table {
  width: 100%; }

.table td, .table th {
  border: 1px solid #A0A0A0;
  padding: 8px 15px; }

.table th {
  background-color: #E46C2A;
  color: #fff; }

.table tbody th {
  background-color: #fff;
  color: #E46C2A; }

.image-style-align-left {
  float: left;
  padding: 20px 20px 20px 0;
  width: 35%; }

.image-style-align-right {
  float: right;
  padding: 20px 0 20px 20px;
  width: 35%; }

.image-style-align-center {
  margin: 0 auto;
  padding: 20px 20px 20px 0;
  width: 35%; }

.about-big-name, .about-mediate-name {
  font-size: 48px;
  font-weight: 600;
  color: #202124;
  text-align: center;
  margin-bottom: 30px; }

.about-mediate-name {
  width: 100%;
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 0; }

.about-indent {
  margin-top: 25px; }

.about-content {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: #444342; }

.side-links {
  position: fixed;
  left: 1px;
  top: 215px;
  z-index: 1; }

.side-link-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 20px 20px 0;
  margin-bottom: 20px;
  background: #fff;
  height: 44px;
  width: 125px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer; }
  .side-link-item:hover {
    -webkit-transition: width .3s;
    transition: width .3s;
    width: 135px; }
  .side-link-item:hover > div {
    color: #444342; }

.side-link-item-text {
  width: 108px;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  color: #acacac; }

.about-image {
  max-width: 260px;
  max-height: 255px; }

.itend-left {
  margin-left: 20px; }

.about-wrapper {
  padding-right: 25px; }

.boss-block {
  width: 100%; }
  .boss-block .boss-structure {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 25px; }
  .boss-block .arrow-right {
    margin-top: 0; }

.boss-block-header {
  height: 27px;
  font-size: 22px;
  font-weight: 600;
  color: #202124;
  margin-top: 25px;
  margin-bottom: 25px; }

.director-row {
  display: flex;
  justify-content: center; }

.sub-director-row {
  display: flex;
  justify-content: space-between;
  margin-top: 25px; }
  .sub-director-row .secondary-slider {
    height: 350px;
    padding: 0;
    margin: 0; }
    @media screen and (max-width: 440px) {
      .sub-director-row .secondary-slider {
        height: 390px; } }
    .sub-director-row .secondary-slider .slick-slide img {
      height: 70px;
      object-fit: cover; }
  .sub-director-row .secondary-slider .slick-slide > div {
    padding: 5px 10px;
    display: flex;
    justify-content: center; }

.structure-item {
  max-width: 195px;
  width: 93%;
  height: 290px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 15px 11px; }

.boss_face {
  text-align: center; }
  .boss_face img {
    display: inline;
    display: initial;
    width: 70px;
    height: auto;
    border-radius: 50%;
    max-width: 100%; }

.boss-fio {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #444342;
  margin-top: 15px;
  text-align: center;
  overflow: hidden;
  max-height: 60px; }
  .boss-fio a:hover {
    text-decoration: underline; }

.boss-position-row, .sub-boss-position-row {
  font-size: 12px;
  line-height: 1.33;
  color: #444342;
  margin-top: 12px;
  display: flex;
  align-items: baseline; }
  .boss-position-row span, .sub-boss-position-row span {
    margin-left: 5px; }

.sub-boss-position-row {
  margin-left: 15px;
  max-height: 88px;
  overflow: hidden; }

.bottom-boss-position-row {
  font-size: 12px;
  line-height: 1.33;
  color: #444342;
  align-items: flex-start; }
  .bottom-boss-position-row span {
    margin-left: 5px;
    display: flex;
    align-items: flex-start;
    max-width: 180px; }

.organizational_structure {
  width: 100%;
  min-height: 620px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  display: flex; }

.left-organizational-column {
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 768px) {
    .left-organizational-column {
      width: 100%; } }

.organizational-pyramid {
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px;
  flex-grow: 1; }
  .organizational-pyramid p, .organizational-pyramid span {
    padding: 0 10px; }
  .organizational-pyramid div:last-child {
    background-color: #f8ca7f;
    text-decoration: underline; }
  .organizational-pyramid .pyramid-director-level {
    width: 100%;
    height: 45px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto; }
    .organizational-pyramid .pyramid-director-level:hover {
      border: 1px solid #df6d17;
      cursor: pointer; }
  .organizational-pyramid .pyramid-level-1, .organizational-pyramid .pyramid-level-2, .organizational-pyramid .pyramid-level-3 {
    width: 90%;
    min-height: 60px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px; }
    .organizational-pyramid .pyramid-level-1:hover, .organizational-pyramid .pyramid-level-2:hover, .organizational-pyramid .pyramid-level-3:hover {
      border: 1px solid #df6d17;
      cursor: pointer; }
  .organizational-pyramid .pyramid-level-2 {
    width: 80%; }
  .organizational-pyramid .pyramid-level-3 {
    width: 70%; }
  .organizational-pyramid span {
    font-size: 12px;
    line-height: 1.33;
    color: #444342;
    margin: 0; }

.organizational-contacts {
  border-top: 1px solid #dbdbdb;
  text-align: left;
  padding: 20px 0 0 15px; }

.organization-address {
  background-color: #ffffff;
  max-height: 80px; }
  .organization-address span {
    height: 27px;
    font-size: 22px;
    font-weight: 600;
    color: #202124; }
    .organization-address span:hover {
      text-decoration: underline;
      cursor: pointer; }

.orgstr-addrr-block {
  padding-top: 21px;
  padding-left: 15px;
  display: block;
  border-top: 1px solid #dbdbdb; }

.orgstr-addrr-header span {
  height: 27px;
  font-size: 22px;
  font-weight: 600;
  color: #202124; }
  .orgstr-addrr-header span:hover {
    text-decoration: underline;
    cursor: pointer; }

.orgstr-addrr-addrr-photo {
  display: flex;
  margin-bottom: 20px; }

.orgstr-right-indent {
  margin-right: 10px; }

.orgstr-addrr-point {
  display: flex; }
  .orgstr-addrr-point .office-address-button {
    background-color: #df6d17;
    color: #fff;
    border-radius: 10px;
    padding: 2px 15px;
    margin-top: 5px; }

.mini-img {
  line-height: 1.88;
  height: 25px;
  width: 25px; }

.orgstr-addrr-contacts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px; }
  .orgstr-addrr-contacts .tel-numbers {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    letter-spacing: 0.1px;
    color: #444342; }

.orgstr-addrr-point-left {
  display: flex;
  margin-bottom: 6px;
  flex-basis: calc(50% - 10px); }

.orgstr-icon-photo {
  width: 128px;
  overflow: hidden;
  margin-right: 20px; }

.orgstr-addrr-office-img {
  width: 100%; }

.bottom-position-item {
  display: flex;
  margin: 10px 0 20px 14px;
  width: 100%;
  flex-direction: row;
  align-items: center; }
  .bottom-position-item img {
    width: 45px;
    height: auto;
    border-radius: 50%;
    max-width: 100%;
    margin-right: 5px; }
  .bottom-position-item .bottom-boss-fio {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #444342; }
    .bottom-position-item .bottom-boss-fio:hover {
      text-decoration: underline;
      cursor: pointer; }
  .bottom-position-item .bottom-name-position {
    padding: 5px; }

.organizational-column-item {
  width: 200px;
  height: 78px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 15px; }
  .organizational-column-item span {
    font-size: 12px;
    line-height: 1.33;
    color: #444342;
    text-align: left;
    width: 85%; }
  .organizational-column-item:hover {
    border: solid 1px #df6d17;
    cursor: pointer; }

.right-organizational-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  flex: auto;
  width: 200px;
  margin: 25px 10px; }

.mobile-organizational-column {
  display: none;
  border-top: 1px solid #dbdbdb;
  padding-top: 20px;
  padding-bottom: 20px; }
  .mobile-organizational-column .organizational-column-item {
    width: 45%;
    height: auto; }

@media all and (max-width: 1410px) {
  .side-links {
    display: none; } }

@media all and (max-width: 1226px) {
  .side-news-block {
    display: none; }
  .no-gutter {
    display: block; } }

@media all and (max-width: 1225px) {
  .news-content-block {
    width: 100%; } }

@media all and (max-width: 768px) {
  .right-organizational-column {
    display: none; }
  .mobile-organizational-column {
    display: block; } }

@media all and (max-width: 440px) {
  .about-mediate-name {
    font-size: 30px; }
  .orgstr-addrr-addrr-photo {
    display: block; }
  .orgstr-addrr-contacts {
    display: block; }
  .structure-item {
    width: 170px; }
  .director-row .structure-item {
    width: 170px; }
  .boss-fio {
    font-size: 13px; }
  .boss-position-row, .sub-boss-position-row {
    font-size: 10px;
    margin-top: 10px; }
    .boss-position-row span, .sub-boss-position-row span {
      font-size: 9px; }
  .bottom-boss-position-row span {
    font-size: 10px; }
  .breadcrumb-wrapper {
    display: none; }
  .boss-block-header {
    font-size: 16px;
    font-weight: 600;
    color: #202124; } }

.accordion {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #fff; }

.partners-wrap .fold .fold_trigger {
  padding: 24px 20px; }
  .partners-wrap .fold .fold_trigger i {
    font-size: 16px;
    margin-top: 5px; }
  .partners-wrap .fold .fold_trigger img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 10px 0 0;
    object-fit: cover; }

.partners-wrap .fold .partners-title {
  font-size: 22px;
  font-weight: 600; }
  @media all and (max-width: 440px) {
    .partners-wrap .fold .partners-title {
      font-size: 16px;
      max-width: 224px; } }

.partners-wrap .folder-item {
  padding: 15px 20px; }

.partners-wrap .accordion-row-content {
  max-width: calc(100% - 93px); }

.partners-wrap .partner-description {
  font-size: 14px; }

.fold {
  border-top: 1px solid #ededed; }
  .fold:first-child {
    border-top: none; }
  .fold .fold-title {
    font-size: 24px;
    font-weight: 800;
    height: 27px;
    padding-left: 30px; }
  .fold .fold_trigger {
    text-align: start;
    width: 100%;
    padding: 24px 0;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    color: #202124; }
    .fold .fold_trigger.open {
      background-color: #ffeccc; }
    .fold .fold_trigger span {
      font-size: 16px;
      font-weight: 500;
      color: #444342; }
    .fold .fold_trigger i {
      float: right; }
    .fold .fold_trigger .partner-counter {
      float: right;
      width: 30px;
      display: flex;
      justify-content: space-between; }
  .fold .fold_content {
    display: none;
    max-height: 0;
    opacity: 0;
    -webkit-transition: max-height 400ms linear;
    transition: max-height 400ms linear; }
    .fold .fold_content.open {
      display: block;
      max-height: 673px;
      opacity: 1;
      overflow-y: auto; }

.about-partner-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  margin-right: 25px; }
  .about-partner-image img {
    object-fit: contain;
    width: 100%;
    height: 100%; }

.folder-item {
  display: flex;
  border-bottom: 1px solid #ededed; }
  .folder-item:last-child {
    border: none; }

.accordion-row-content {
  max-width: 100%;
  color: #202124; }
  .accordion-row-content span {
    font-size: 22px;
    font-weight: 500; }
    .accordion-row-content span:hover {
      text-decoration: underline;
      color: #df6d17; }

.partners-wrap {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  margin-top: 25px; }

.affiliates-address {
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342; }

.accordion-body {
  background: #fff;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: calc(100% + 1px); }

.accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05); }

.accordion-button {
  padding: 20px 15px; }
  .accordion-button:hover {
    cursor: pointer; }

.accordion-wrapper {
  padding-right: 20px; }

.org-structure {
  display: flex;
  width: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15); }
  @media screen and (max-width: 768px) {
    .org-structure {
      flex-direction: column; } }

.org-structure-panel {
  width: 50%;
  padding: 20px;
  height: 570px; }
  @media screen and (max-width: 768px) {
    .org-structure-panel {
      width: 100%; } }
  .org-structure-panel .active {
    background-color: #f8ca7f; }
  .org-structure-panel .parent {
    padding: 18px;
    text-align: center;
    font-size: 12px;
    line-height: 1.33;
    color: #444342;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .org-structure-panel .parent:hover {
      border: solid 1px #df6d17; }
  .org-structure-panel .parent-nodes {
    padding: 20px;
    overflow: auto; }
  .org-structure-panel.parent-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    padding: 0; }
  .org-structure-panel.children-panel {
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
    align-content: flex-start; }
  .org-structure-panel .children {
    flex-basis: calc(50% - 5px);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    cursor: pointer; }
    .org-structure-panel .children:nth-child(odd) {
      margin-right: 10px;
      flex-basis: calc(50% - 5px); }
    .org-structure-panel .children p {
      font-size: 12px;
      line-height: 1.33;
      color: #444342;
      margin-right: 10px; }
    .org-structure-panel .children:hover {
      border: solid 1px #df6d17; }
    @media screen and (max-width: 995px) {
      .org-structure-panel .children {
        flex-basis: 100%; }
        .org-structure-panel .children:nth-child(odd) {
          flex-basis: 100%;
          margin-right: 0; } }
  .org-structure-panel .arrow {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #df6d17; }
  .org-structure-panel .section-contacts {
    max-height: 450px; }
  .org-structure-panel .org-fold {
    display: flex;
    justify-content: space-between;
    min-height: 70px;
    border-top: 1px solid #eee;
    padding-left: 20px; }
    .org-structure-panel .org-fold .folder-button {
      cursor: pointer;
      padding-bottom: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-shrink: 0;
      min-height: 68px;
      width: 55px; }
    .org-structure-panel .org-fold .folder-title {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .org-structure-panel .org-fold .folder-title .name {
        margin-top: 10px;
        margin-bottom: 5px; }
        .org-structure-panel .org-fold .folder-title .name span {
          font-size: 16px;
          font-weight: 600; }
    .org-structure-panel .org-fold .link {
      font-size: 18px;
      font-weight: 600; }
      .org-structure-panel .org-fold .link:hover {
        text-decoration: underline; }
  .org-structure-panel .org-fold-cont {
    padding: 20px;
    padding-top: 0;
    overflow-y: auto;
    max-height: 300px; }
    .org-structure-panel .org-fold-cont .address-block {
      padding-top: 0;
      display: flex; }
    .org-structure-panel .org-fold-cont .address {
      margin-left: 5px;
      color: #444342; }
      .org-structure-panel .org-fold-cont .address p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        padding: 3px 0; }
    .org-structure-panel .org-fold-cont .picture {
      flex-shrink: 0;
      width: 30%; }
    .org-structure-panel .org-fold-cont .avatar {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden; }
    .org-structure-panel .org-fold-cont .location {
      margin-left: 25px;
      width: 20px;
      height: 25px;
      flex-shrink: 0; }
    .org-structure-panel .org-fold-cont .contacts {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap; }
      .org-structure-panel .org-fold-cont .contacts .one-contact {
        flex-shrink: 0;
        min-width: calc(50% - 5px);
        display: flex;
        margin: 5px 0; }
  .org-structure-panel .position {
    display: flex;
    margin-bottom: 10px; }
    .org-structure-panel .position .arrow {
      margin-top: 5px; }
    .org-structure-panel .position span {
      font-size: 14px;
      line-height: 1.5;
      margin-left: 10px; }

.org-structure-person {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px; }
  .org-structure-person .name > a {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #444342; }
  .org-structure-person .position {
    display: flex;
    align-items: center; }
    .org-structure-person .position > div {
      margin-right: 10px; }
    .org-structure-person .position > span {
      line-height: 1.33;
      color: #444342; }
  .org-structure-person .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 15px; }
    .org-structure-person .avatar img {
      width: 100%; }

.org-contact-block .adr-block {
  margin: 0 -10px; }

.contact-big-name {
  font-size: 36px;
  font-weight: 600;
  margin: 10px 0 25px;
  color: #202124;
  text-align: center; }

.contact-block {
  position: relative;
  width: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-top: 1px solid #ededed; }

.press-center {
  /* height: 180px; */
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e9e9e9;
  margin: 0 25px 25px 25px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 15px; }

.main-office {
  width: 452px;
  height: 212px;
  margin-left: 44px;
  background-color: #ffffff; }
  .main-office span {
    height: 27px;
    font-size: 22px;
    font-weight: 600;
    color: #202124; }

.office-image-address-block {
  margin-top: 15px;
  display: flex; }

.map-point-icon {
  width: 63px;
  text-align: center; }

.office-address {
  width: 250px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342; }

.telephone-numbers {
  display: flex;
  margin-top: 13px; }
  .telephone-numbers .tel-numbers {
    margin-left: 15px;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    letter-spacing: 0.1px;
    color: #444342; }

.fax-site {
  margin-left: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342; }

.press-contact {
  padding: 18px 0 7px 18px;
  border-bottom: 1px solid #e9e9e9; }
  .press-contact .orgstr-addrr-contacts {
    margin-bottom: 8px; }

.orgstr-addrr-header {
  margin-bottom: 15px; }

.split-press {
  border: none;
  color: #e9e9e9;
  background-color: #e9e9e9;
  height: 1px;
  margin-bottom: -2px;
  margin-top: 0; }

.contact-form-header {
  text-align: center;
  height: 27px;
  font-size: 22px;
  font-weight: 600;
  color: #202124; }

.feedback-input, .feedback-input-error, .feedback-textarea, .feedback-textarea-error, .captcha-block .captcha-input {
  height: 40px;
  border-radius: 6px;
  border: solid 1px #ededed;
  background-color: #f6f6f6;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #202124; }
  .feedback-input::-webkit-input-placeholder, .feedback-input-error::-webkit-input-placeholder, .feedback-textarea::-webkit-input-placeholder, .feedback-textarea-error::-webkit-input-placeholder, .captcha-block .captcha-input::-webkit-input-placeholder {
    text-align: right; }
  .feedback-input::-moz-placeholder, .feedback-input-error::-moz-placeholder, .feedback-textarea::-moz-placeholder, .feedback-textarea-error::-moz-placeholder, .captcha-block .captcha-input::-moz-placeholder {
    text-align: right; }
  .feedback-input::-ms-input-placeholder, .feedback-input-error::-ms-input-placeholder, .feedback-textarea::-ms-input-placeholder, .feedback-textarea-error::-ms-input-placeholder, .captcha-block .captcha-input::-ms-input-placeholder {
    text-align: right; }
  .feedback-input::placeholder, .feedback-input-error::placeholder, .feedback-textarea::placeholder, .feedback-textarea-error::placeholder, .captcha-block .captcha-input::placeholder {
    text-align: right; }

.feedback-input-error {
  border: solid 1px #ed0b13; }

.email-depart {
  display: flex; }

.left {
  padding: 39px 25px 20px 25px; }
  .left span {
    height: 14px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    color: #202124; }

.feedback-textarea, .feedback-textarea-error {
  height: 135px;
  padding-top: 5px; }

.feedback-textarea-error {
  border: solid 1px #ed0b13; }

.button-contact-submit {
  width: 200px;
  height: 49px;
  border-radius: 100px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.12);
  background-color: #df6d17;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff; }
  .button-contact-submit:hover {
    text-decoration: underline;
    color: #ffffff; }

.sub-but {
  text-align: center;
  margin: 20px; }

.captcha-block span {
  height: 14px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  color: #202124; }

.captcha-block .captcha-inputs {
  display: flex; }

.captcha-block .captcha-input {
  height: 35px; }

.equal-height {
  display: flex;
  align-items: stretch;
  margin-bottom: 50px;
  padding-bottom: 15px; }

.department-block {
  position: relative; }

.depart-wrapper {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);
  margin-top: -20px;
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #ffffff; }
  .depart-wrapper li {
    padding: 16px 14px;
    font-size: 14px; }
    .depart-wrapper li:hover {
      text-decoration: underline;
      color: #df6d17; }

.map-block {
  position: relative;
  margin-top: -23px; }
  .map-block img {
    max-width: 100%;
    width: 100%; }

.shift-map {
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: rgba(172, 172, 172, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }
  .shift-map .shift-map-arrow {
    font-size: 24px;
    color: #ffffff; }
  .shift-map:hover {
    cursor: pointer;
    background-color: rgba(140, 140, 140, 0.8); }

.vo {
  height: 100%;
  width: 100%; }
  .vo iframe {
    height: 619px; }

.contact-orgstr-addrr-block {
  padding: 0 30px;
  display: block; }

.mobile-map {
  width: 90%;
  margin: 0 auto 20px; }
  .mobile-map img {
    max-width: 100%;
    width: 100%;
    height: auto; }

.press-center-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #202124; }

@media only screen and (max-width: 1200px) {
  .right {
    height: 60vw; } }

@media only screen and (max-width: 440px) {
  .fold {
    padding: 0 .5em 0 .5em; }
    .fold .affiliates-address {
      width: 200px;
      line-height: 1; }
    .fold .fold_trigger {
      font-size: 18px; }
  .contact-big-name {
    font-size: 28px; } }

.editions-page .secondary-slider:before {
  width: 12px; }

.no-left-gutter {
  margin-left: 0; }

.books-category-header {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.1;
  color: #202124; }

.content-item-wrapper {
  width: 100%; }

.book-image {
  max-height: 290px;
  width: 100%; }

.book-text_wr {
  height: 95px;
  padding: 8px 15px 10px 15px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.6)), to(#fff));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6), #fff);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), #fff);
  font-weight: 600;
  color: #202124;
  position: absolute;
  width: 100%;
  bottom: 74px;
  max-height: 96px;
  font-size: 14px;
  line-height: 1.71;
  overflow: hidden; }

.books-category {
  margin-top: 25px;
  margin-bottom: 27px; }

.book-view-header {
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.26;
  color: #202124;
  margin: 10px 0 20px 0; }

.product-book-block {
  display: flex;
  justify-content: space-between; }
  .product-book-block .product-book-image {
    max-width: 270px;
    width: 100%; }
    .product-book-block .product-book-image img {
      height: auto;
      width: auto;
      max-height: 360px;
      max-width: 100%; }
  .product-book-block .product-book-info {
    flex: 1 1;
    padding: 0 30px 0 30px; }
    .product-book-block .product-book-info table {
      table-layout: fixed;
      width: 100%; }
    .product-book-block .product-book-info td {
      text-align: left; }
    .product-book-block .product-book-info td:first-child {
      overflow: hidden;
      width: 35%; }
    .product-book-block .product-book-info .book-table-key {
      height: 25px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #acacac;
      padding: 5px 0;
      vertical-align: baseline; }
    .product-book-block .product-book-info .book-table-value {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #5e5e5e;
      vertical-align: baseline;
      padding: 5px 0; }
      .product-book-block .product-book-info .book-table-value span + span:before {
        content: ',';
        padding-right: 5px; }

.product-price-block {
  text-align: left;
  margin-top: 24px; }

.comment-rating-ratio {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #202124;
  margin-left: 15px; }

.book-buy-button {
  flex-shrink: 0;
  width: 250px;
  height: 49px;
  border-radius: 100px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.12);
  background-color: #df6d17;
  padding: 10px 0 10px 0;
  margin-top: 18px; }
  .book-buy-button span {
    height: 29px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-top: 1px; }
    .book-buy-button span:hover {
      cursor: pointer;
      color: #fff; }

.edition-block-description {
  padding-right: 20px; }
  @media screen and (max-width: 768px) {
    .edition-block-description {
      padding-right: 0; } }

.product-book-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: .1px;
  color: #666;
  margin-top: 25px; }

.product-book-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.book-comments-header {
  display: flex;
  margin-top: 25px;
  height: 46px;
  align-items: center; }
  .book-comments-header .comment-main-header, .book-comments-header .comments-ratio-numbers {
    height: 27px;
    font-size: 22px;
    font-weight: 600;
    color: #202124; }
  .book-comments-header .comments-ratio-numbers {
    color: #acacac;
    margin-left: 20px; }
  .book-comments-header .book-leave-comment-block {
    width: 73px;
    height: 46px;
    border-radius: 93.9px;
    box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.12);
    background-color: #df6d17;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    text-align: center; }
    .book-comments-header .book-leave-comment-block:hover {
      cursor: pointer;
      color: #fff; }

.book-comment-block {
  margin-top: 12px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  margin-right: 24px; }
  .book-comment-block .comment-content-block {
    font-size: 14px;
    line-height: 1.57;
    color: #202124; }
  .book-comment-block .comment-block-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
  .book-comment-block .comment-block-item {
    border-bottom: 1px solid #ededed;
    padding: 20px; }
  .book-comment-block .comment-author {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    color: #444342;
    text-align: left; }

.rating-comment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 150px;
  font-size: 14px; }

.row-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden; }
  .row-main.with-arrows .secondary-slider {
    padding-bottom: 85px; }
  .row-main .secondary-slider {
    height: auto; }
  .row-main .small-card {
    width: 92%;
    overflow: hidden; }

.with-hr {
  position: relative;
  margin-left: -10px;
  padding-left: 15px; }
  .with-hr:after {
    content: '';
    position: absolute;
    background: #202124;
    left: 10px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 20px); }

.comment-tag-block {
  margin-top: 26px; }

.books-bottom-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px; }

.books-rate-tags-block {
  display: flex;
  height: 15px;
  font-size: 12px;
  color: #444342;
  margin-left: 5px;
  align-items: center;
  bottom: 20px;
  margin-top: 10px;
  width: 100px; }

.book-share-wrapper {
  position: relative;
  margin: 0 15px 0 0;
  height: 30px;
  width: 35px;
  padding-top: 10px; }

.book-tag-num {
  font-size: 12px;
  font-weight: 100; }

.book-filter-block-wrapper {
  width: 100%; }

.editions-big-name {
  height: 58px;
  font-size: 36px;
  font-weight: 600;
  color: #202124;
  text-align: center; }

.accordion-button-books img {
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-left: 15px; }

.books-accordion-wrapper.opened .accordion-button-books img {
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-left: 15px; }

.books-accordion-wrapper.opened accordion-head {
  flex-wrap: wrap; }

.book-item {
  margin: 8px 0;
  width: 100%; }

.in-slider {
  margin-left: 20px; }

.not-found-text {
  font-size: 22px;
  padding: 20px 0; }

@media only screen and (max-width: 510px) {
  .book-view-header {
    font-size: 18px;
    margin-bottom: 20px;
    height: auto; }
  .product-book-block .product-book-info {
    padding-left: 10px; }
  .product-book-block .product-book-image {
    margin: 0 auto; } }

@media only screen and (max-width: 399px) {
  .books-filter-row {
    flex-wrap: wrap; }
  .books-filter-tags-wrapper .filters-list .filters-item {
    width: 50%; }
  .books-category-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #202124;
    margin-left: 30px;
    margin-bottom: -20px; }
  .product-book-block .product-book-info table {
    table-layout: fixed;
    width: 100%;
    margin-top: 5px; }
  .product-book-block .product-book-info td {
    text-align: left; }
  .product-book-block .product-book-info td:first-child {
    overflow: hidden;
    width: 50%;
    vertical-align: top;
    padding-right: 30px;
    padding-top: 3px; } }

.filtered-books-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px; }

.rating {
  display: inline-block; }

.star-icon {
  background: url(/static/media/star-grey.fe84aff0.svg) 50% no-repeat;
  background-size: cover;
  margin-right: 2px;
  height: 10px;
  width: 10px; }

.star-icon.full {
  background: url(/static/media/star-fill.507caf0b.svg) 50% no-repeat; }

.star-icon.half {
  background: url(/static/media/star-half.32c5753c.svg) 50% no-repeat; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999; }
  .modal.display-block {
    display: block; }
  .modal.display-none {
    display: none; }

.modal-background {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; }

.modal-book-content {
  position: fixed;
  background: white;
  width: 700px;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 40px 50px;
  z-index: 20; }
  @media screen and (max-width: 768px) {
    .modal-book-content {
      width: 85%;
      position: absolute;
      -webkit-transform: none;
              transform: none;
      top: auto;
      left: auto;
      overflow: auto;
      height: 100%; } }
  .modal-book-content .react-rater {
    white-space: nowrap;
    height: 50px;
    margin-top: 5px; }
    .modal-book-content .react-rater div {
      display: inline-block; }
      .modal-book-content .react-rater div + div {
        margin-left: 12px; }
  .modal-book-content .react-rater-star {
    font-size: 53px;
    line-height: 56px;
    color: #ededed !important; }
    .modal-book-content .react-rater-star.is-active {
      color: #ffd543 !important; }
    .modal-book-content .react-rater-star.will-be-active {
      color: #f8ca7f !important; }
  .modal-book-content .close-btn {
    float: right;
    height: 27px; }
    .modal-book-content .close-btn img {
      height: 27px; }
  .modal-book-content form {
    text-align: center; }
  .modal-book-content .modal-header {
    text-align: center; }
  .modal-book-content h2 {
    font-size: 22px;
    font-weight: 600;
    color: #202124;
    display: inline-block;
    margin-bottom: 25px; }
  .modal-book-content input {
    height: 46px;
    border-radius: 6px;
    border: solid 1px #ededed;
    background-color: #f6f6f6;
    padding: 16px 24px;
    font-size: 14px;
    color: #202124;
    margin-top: 11px; }
    .modal-book-content input.error {
      border-color: #df6d17; }
  .modal-book-content textarea.error {
    border-color: #df6d17; }
  .modal-book-content label {
    color: #202124;
    font-size: 12px;
    font-weight: bold;
    width: 47%;
    position: relative;
    display: inline-block;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .modal-book-content label {
        width: 100%; } }
  .modal-book-content .textarea-label {
    width: 100%; }
  .modal-book-content .form-group-textarea {
    width: 100%;
    text-align: left; }
  .modal-book-content .form-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap; }

.book-delivery-types {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.14);
  margin-top: -2px;
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #ffffff; }
  .book-delivery-types li {
    padding: 16px 14px;
    font-size: 14px; }
    .book-delivery-types li:hover {
      text-decoration: underline;
      color: #df6d17; }

.rotating-loading {
  text-align: center;
  width: 100%;
  margin: 25px 0 0; }
  .rotating-loading img {
    -webkit-animation: rotating 4s linear infinite;
    animation: rotating 4s linear infinite; }

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.books-accordion-wrapper {
  position: relative;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15); }
  .books-accordion-wrapper .accordion-head {
    padding: 5px;
    flex-wrap: wrap; }
    .books-accordion-wrapper .accordion-head .search-block {
      max-width: 335px; }
    .books-accordion-wrapper .accordion-head .search-block input {
      height: 35px;
      min-width: 200px; }
    .books-accordion-wrapper .accordion-head .search-block button {
      background-size: 70%;
      height: 35px;
      width: 35px; }
  .books-accordion-wrapper .accordion-body {
    display: flex;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    padding: 25px 15px 15px 15px; }

.accordion-button-books {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  margin-left: 16px; }
  .accordion-button-books:hover {
    cursor: pointer; }

.books-count-image {
  max-width: 60px;
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  align-items: center;
  white-space: nowrap; }
  .books-count-image span {
    height: 20px;
    font-size: 14px;
    line-height: 1.43;
    color: #444342;
    margin-left: 4px; }

.books-filter-wrapper {
  max-width: 190px;
  /* width*/
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .books-filter-wrapper ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .books-filter-wrapper scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .books-filter-wrapper ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px; }
  .books-filter-wrapper ::-webkit-scrollbar-thumb {
    background-color: #444342;
    border-radius: 5px; }
  .books-filter-wrapper ::-webkit-scrollbar-thumb:hover {
    background: #858585; }

.books-filter-tags-wrapper {
  /* width*/
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .books-filter-tags-wrapper ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .books-filter-tags-wrapper scrollbar {
    width: 12px;
    background-color: rgba(223, 109, 23, 0.15); }
  .books-filter-tags-wrapper ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px; }
  .books-filter-tags-wrapper ::-webkit-scrollbar-thumb {
    background-color: #444342;
    border-radius: 5px; }
  .books-filter-tags-wrapper ::-webkit-scrollbar-thumb:hover {
    background: #858585; }

.books-type-wrapper {
  max-width: 190px; }
  .books-type-wrapper .books-filter-list {
    -webkit-flex-wrap: wrap;
    max-height: 160px;
    overflow-y: hidden;
    overflow-x: hidden; }

.books-filter-list {
  -webkit-flex-wrap: wrap;
  max-height: 160px;
  overflow-y: scroll;
  overflow-x: hidden; }

.books-filter-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 174px;
  padding: 10px 10px 10px 0; }
  .books-filter-item label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%; }
    .books-filter-item label:hover {
      cursor: pointer; }
  .books-filter-item input:checked + .checkbox-facade {
    background: #df6d17;
    background-image: url(/static/media/CheckBox.5b533837.svg); }

.books-filter-row {
  display: flex;
  justify-content: space-between; }

.page-wrapper {
  width: 100%; }

.state-page .secondary-slider {
  height: 100%;
  padding-bottom: 40px; }
  .state-page .secondary-slider .slick-arrow {
    bottom: -65px; }

.state-page .list-item_wr {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0);
  height: 155px; }
  .state-page .list-item_wr:hover {
    box-shadow: 0 10px 25px 0 rgba(223, 109, 23, 0.3);
    cursor: pointer; }

.state-page .slider-big {
  padding-bottom: 75px; }
  .state-page .slider-big .warning-item {
    height: 221px; }
    .state-page .slider-big .warning-item .list-item_image {
      background: transparent; }

.state-page .list-item_text {
  font-weight: 600;
  font-size: 14px;
  color: #444342; }

.state-page .list-item_image img {
  max-width: 100%;
  width: 100%; }

.state-page .warning-item .list-item_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0; }
  .state-page .warning-item .list-item_image img {
    max-width: 90px;
    width: 100%; }

.state-page .warning-item .list-item_text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  padding: 10px 0 45px;
  text-align: center;
  margin: 0 auto;
  color: #5e5e5e;
  width: 80%; }

.page-title {
  font-size: 36px;
  font-weight: 600;
  color: #202124;
  padding: 10px 0; }

.page-subtitle {
  font-size: 16px;
  padding: 10px 0 15px;
  font-weight: bold; }
  .page-subtitle * {
    margin-right: 20px; }

.page-content > p {
  font-size: 16px;
  padding: 15px 0; }

.secondary-slider {
  height: 250px; }

.section-wrapper {
  margin-bottom: 50px;
  clear: both; }

.section-title {
  font-size: 22px;
  margin: 25px 0;
  font-weight: 600;
  color: #202124; }

.section-subtitle a {
  text-decoration: underline; }
  .section-subtitle a:hover {
    color: #df6d17; }

.paper-list {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  margin-right: 20px; }
  .paper-list li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    font-size: 14px; }
    .paper-list li:last-child {
      border: none; }
    .paper-list li a {
      padding: 25px 30px;
      display: block; }
    .paper-list li:hover {
      background-color: rgba(223, 109, 23, 0.15);
      padding-left: 45px;
      cursor: pointer; }
      .paper-list li:hover:before {
        content: '';
        position: absolute;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 5px solid #df6d17;
        left: 30px; }

@media all and (max-width: 1080px) {
  .state-page .chart-item {
    width: 100%; }
  .state-page .charts-wrapper {
    padding-right: 0; }
  .state-page .secondary-slider {
    height: 285px;
    margin-left: 0;
    padding-left: 0; }
  .paper-list {
    margin-right: 0; }
  .page-content > p {
    font-size: 14px;
    padding: 10px 0; } }

.charts-period-wrapper {
  min-width: 245px;
  margin-right: 10px; }
  .charts-period-wrapper ul, .charts-period-wrapper li {
    display: inline-block;
    margin: 0 2px; }
  @media all and (max-width: 768px) {
    .charts-period-wrapper {
      padding-top: 15px; } }
  .charts-period-wrapper li {
    padding: 2px 5px;
    cursor: pointer; }
    .charts-period-wrapper li.active {
      background: #df6d17;
      color: #fff; }

.charts-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px; }

.chart-item {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  width: calc(50% - 12.5px);
  margin-bottom: 25px;
  font-size: 14px;
  padding: 25px; }

.chart-item_title {
  font-weight: 600;
  color: #333; }

.chart-item_nav {
  display: flex;
  flex-wrap: wrap; }
  .chart-item_nav li {
    background: #f6f6f6;
    padding: 5px 20px;
    margin: 0 5px 5px 0; }
    .chart-item_nav li:hover {
      background: #df6d17;
      cursor: pointer;
      color: #fff; }
  .chart-item_nav .active {
    background: #df6d17;
    color: #fff; }

.chart-item_area {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
  margin-top: 25px;
  font-size: 12px; }

.affiliate-address-director {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.affiliate-office-contact, .affiliate-director-contact {
  width: 49%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }

.affiliate-director-contact {
  padding-top: 21px;
  padding-left: 15px;
  padding-bottom: 30px; }

.affiliate-contacts {
  text-align: left;
  padding: 20px 0 0 15px; }

.affiliate-boss-identity {
  display: flex;
  margin-top: 12px; }
  .affiliate-boss-identity span {
    max-width: 245px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 0.1px;
    color: #444342; }
    .affiliate-boss-identity span:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #444342; }

.affiliate-street-spot {
  display: flex; }
  .affiliate-street-spot span {
    max-width: 245px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 0.1px;
    color: #444342; }

.affiliate-boss-image {
  width: 90px; }

.aff-map-point-icon {
  margin-right: 15px; }

.boss-name-block {
  margin-left: 5px; }
  .boss-name-block span {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: #444342;
    margin-left: 10px; }

.affiliate-common-header {
  font-size: 22px;
  font-weight: 600;
  color: #202124;
  background-color: #ffffff; }

.organization-explain-block {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-top: 25px;
  padding: 15px; }
  .organization-explain-block p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: 0.1px;
    color: #444342; }

.structure-row {
  display: flex; }
  .structure-row span {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    letter-spacing: 0.1px;
    color: #444342;
    margin-left: 10px; }
  .structure-row .arrow-right-black {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #0a0a0a;
    margin-top: 9px; }

.one-indentation {
  margin-top: 12px; }

.two-indentation {
  padding-left: 12px; }

.organization-security-block {
  margin-top: 25px; }

.affiliate-security-director {
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;
  margin-bottom: 12px; }

.security-boss-name-block {
  margin-left: 10px; }

.security-boss-name-header {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #202124;
  margin-bottom: 12px; }

.security-contact-top-intend {
  margin-top: 25px;
  margin-bottom: 14px; }

.department-main-direction {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342; }

.tel-numbers, .tel-numbers p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  white-space: nowrap;
  color: #444342; }

.affiliate-mediate-name {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: #202124; }

@media only screen and (max-width: 800px) {
  .affiliate-address-director {
    flex-wrap: wrap; }
  .affiliate-office-contact, .affiliate-director-contact {
    width: 100%; }
  .affiliate-mediate-name {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #202124; } }

@media only screen and (max-width: 400px) {
  .affiliate-address-director {
    display: block; }
  .affiliate-office-contact, .affiliate-director-contact {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 20px; }
  .affiliate-director-contact {
    width: 100%; }
  .news-item-wrapper {
    padding-right: 0; }
  .affiliate-boss-identity {
    display: block; }
  .boss-name-block {
    display: flex; }
  .tel-numbers p {
    font-size: 14px; }
  .breadcrumb-wrapper {
    display: none; }
  .affiliate-common-header {
    font-size: 16px;
    font-weight: 600;
    color: #202124; }
  .affiliate-mediate-name {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #202124; }
  .affiliate-security-director img {
    max-width: 100%;
    width: 100%; } }

.slider-media .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.slider-media h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.25;
  padding: 15px 0;
  color: #fff; }

.slider-media .slick-arrow {
  background: url(/static/media/arrow.6997b175.svg) 50% no-repeat;
  background-size: 8px;
  position: absolute;
  font-size: 0;
  height: 50px;
  width: 50px;
  top: calc(50% - 25px);
  z-index: 10; }
  .slider-media .slick-arrow:hover {
    background: url(/static/media/arrow-hover.68145449.svg) 50% no-repeat;
    background-size: 8px; }

.slider-media .slick-prev {
  left: -80px; }
  .slider-media .slick-prev:hover:after {
    content: '';
    border-radius: 0 50px 50px 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    position: absolute;
    height: calc(100% - 2px);
    border-left: none;
    width: 50%;
    right: 0;
    top: 1px; }
  .slider-media .slick-prev:hover:before {
    border-radius: 50px 0 0 50px;
    border-bottom: 3px solid #df6d17;
    border-left: 3px solid #df6d17;
    border-top: 3px solid #df6d17;
    width: 50%; }

.slider-media .slick-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  right: -80px; }
  .slider-media .slick-next:hover:after {
    content: '';
    border-radius: 0 50px 50px 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    position: absolute;
    height: calc(100% - 2px);
    border-left: none;
    width: 50%;
    right: 0;
    top: 1px; }
  .slider-media .slick-next:hover:before {
    border-radius: 50px 0 0 50px;
    border-bottom: 3px solid #df6d17;
    border-left: 3px solid #df6d17;
    border-top: 3px solid #df6d17;
    width: 50%;
    left: 1px; }

.slider-media .slick-prev:after,
.slider-media .slick-next:after {
  content: '';
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  top: 1px;
  right: 0; }

.slider-media .slick-prev:before,
.slider-media .slick-next:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

.slider-media .slick-dots {
  display: flex !important;
  position: absolute;
  justify-content: center;
  bottom: -70px;
  right: 0;
  left: 0; }
  .slider-media .slick-dots li {
    position: relative;
    margin: 5px;
    font-size: 0;
    height: 15px;
    width: 15px; }
    .slider-media .slick-dots li:hover {
      border: 1px solid #df6d17;
      border-radius: 50%;
      cursor: pointer; }
    .slider-media .slick-dots li:after {
      content: '';
      background: #fff;
      border-radius: 50%;
      position: absolute;
      overflow: hidden;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0; }
    .slider-media .slick-dots li button {
      position: relative;
      z-index: 10;
      height: 100%;
      width: 100%; }
  .slider-media .slick-dots .slick-active:after {
    background: #df6d17; }

.slider-media .slick-slide img {
  max-width: 100%;
  max-height: 65vh;
  display: inline; }

.slider-media_top-item {
  position: relative;
  text-align: center;
  min-height: 100px;
  background: rgba(255, 255, 255, 0.3); }
  .slider-media_top-item h5 {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    text-align: left;
    padding: 15px;
    width: 100%;
    bottom: 0; }

.slider-media_bot-item {
  text-align: center;
  min-height: 50px; }
  .slider-media_bot-item h5 {
    text-align: left;
    padding: 7px 15px 15px 15px;
    color: #fff; }

.slider-media_bot .slick-slider {
  overflow: hidden; }

.slider-media_bot .slick-slide > div {
  width: calc(100% - 12.5px);
  margin: 0 0 15px; }

.slider-media_bot .slick-arrow {
  bottom: 0;
  top: auto; }

.slider-media_bot .slick-prev {
  left: 25%; }

.slider-media_bot .slick-next {
  right: 25%; }

.slider-media_bot .slick-dots {
  bottom: 0; }

.slider-media_bot .slick-list {
  padding-top: 10px;
  padding-left: 20px;
  margin-left: -20px;
  overflow: hidden;
  height: 230px; }

.slider-media.slider-media-files .slick-arrow {
  bottom: -55px;
  top: auto; }

.slider-media.slider-media-files .slick-prev {
  left: 25%; }

.slider-media.slider-media-files .slick-next {
  right: 25%; }

.slider-media.slider-media-files .slick-dots {
  bottom: -60px; }

.slider-media.slider-media-files h5 {
  text-align: left;
  padding: 15px 0;
  color: #fff; }

.slider-media.slider-media-files .slider-files-item {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap; }

.slider-media.slider-media-files .slider-files-item li {
  width: calc(25% - 12.5px); }

.slider-media.slider-media-files .slider-files-sub img {
  border: 2px solid transparent; }

.slider-media.slider-media-files .slider-files-sub.selected img {
  border: 2px solid #df6d17; }

.slider-media.slider-media-files .download-button {
  position: absolute;
  border-radius: 50px;
  background: #df6d17;
  padding: 10px;
  font-size: 24px;
  width: 100px;
  color: #fff;
  bottom: -55px; }

@media all and (max-width: 576px) {
  .chart-item {
    overflow: auto; }
  .slider-media_bot {
    display: none; } }

@media all and (max-width: 320px) {
  .secondary-slider .slick-next {
    right: calc(50% - 128px); }
  .secondary-slider .slick-prev {
    left: calc(50% - 128px); } }

.App-footer {
  background: url(/static/media/footer-back.fc4af09d.jpg) 50% no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 1; }

.footer-static-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

footer .telegram-button {
  position: relative;
  background: transparent; }
  footer .telegram-button:hover {
    cursor: pointer; }
    footer .telegram-button:hover .telegram-list-footer {
      display: block; }
  footer .telegram-button:after {
    display: none; }
  footer .telegram-button .telegram-list-footer {
    display: none;
    position: absolute;
    top: auto;
    bottom: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 300px;
    background-color: #302e33;
    box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.14); }
    footer .telegram-button .telegram-list-footer li {
      border-bottom: 1px solid #7d7d7d; }
    @media all and (max-width: 768px) {
      footer .telegram-button .telegram-list-footer {
        max-width: 200px; } }

.footer-centered {
  height: auto;
  padding: 5px 5px 30px 5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  margin-top: 40px; }

.footer-centered-confidential {
  display: flex;
  align-items: center;
  border-top: 1px solid #3f4245;
  padding: 15px 10px;
  margin-top: 20px;
  height: 60px;
  width: 100%;
  color: #fff; }

.footer-links {
  margin-top: -25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 1; }
  .footer-links a {
    color: white;
    text-decoration: none; }
    .footer-links a:hover {
      color: #df6d17;
      text-decoration: underline; }

.footer-links a.link-header.no-underline:hover {
  text-decoration: none; }

.footer-link-category {
  width: calc(25% - 10px); }

.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #df6d17;
  margin-top: 3px; }

.footer-link-block-header {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  font-size: 13px; }

.link-header {
  font-size: 16px;
  margin-left: 3px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: bold; }

.footer-sublinks {
  margin-left: 12px;
  margin-bottom: 10px;
  color: white;
  font-size: 14px;
  line-height: 1.3; }

#footer-copyright {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  color: gray; }

.confidential-condition-block a {
  font-size: 12px;
  float: right;
  text-decoration: none;
  color: #fff;
  margin-left: 20px;
  text-transform: uppercase; }
  .confidential-condition-block a:hover {
    text-decoration: underline;
    color: #df6d17; }

#footer-contacts p {
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  padding-left: 58px; }

.footer-social-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px; }

.footer-logo-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px; }

.footer-centered > .footer-logo-block {
  display: none; }

@media all and (max-width: 1080px) {
  .footer-logo-block {
    width: 100%;
    padding-bottom: 10px;
    justify-content: flex-start;
    margin-bottom: 0; }
  footer .footer-static-block {
    flex-direction: row; }
  .footer-centered {
    border-bottom: .5px solid #5e5e5e;
    padding: 0; }
  .footer-link-category {
    width: calc(50% - 10px); }
  .footer-social-block {
    margin: 0; }
  .footer-static-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: .5px solid #5e5e5e;
    justify-content: space-between;
    align-items: center;
    padding: 10px; } }

.footer-centered-confidential.outside-confidential {
  display: none; }

@media all and (max-width: 1080px) {
  .footer-link-block-header {
    padding: 10px 0; }
  .footer-centered-confidential.outside-confidential {
    display: flex; }
  .footer-social-block {
    margin: 0 auto; }
  .footer-centered #footer-contacts,
  .footer-centered .footer-centered-confidential,
  .footer-centered .footer-static-block .footer-logo-block {
    display: none; } }

@media all and (max-width: 768px) {
  .footer-link-block-header {
    padding: 10px 0;
    margin-top: 0; }
  .footer-sublinks {
    display: none; }
  .footer-no-links {
    display: none; }
  .footer-centered > .footer-logo-block {
    display: flex; }
  .footer-links {
    flex-direction: column;
    margin-top: 0; }
  .footer-link-category {
    width: 100%; }
  .link-header {
    margin-bottom: 5px;
    font-weight: bold; }
  .footer-social-block {
    margin: 0 auto; } }

