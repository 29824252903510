.table {
  table {
    width: 100%;
    //width: auto;
  }
  td, th {
    border: 1px solid #A0A0A0;
    padding: 8px 15px;
    img {
      //height: 140px;
      //width: auto;
    }
  }
  th {
    background-color: #E46C2A;
    color: #fff;
  }
  tbody th {
    background-color: #fff;
    color: #E46C2A;
  }
}
