@mixin font-styled {
  height: 27px;
  font-size: 22px;
  font-weight: 600;
  color: #202124;
}

@mixin telephone-styled {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  letter-spacing: 0.1px;
  color: #444342;
}

@mixin input-name {
  height: 14px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  color: #202124;
}

.contact-big-name {
  font-size: 36px;
  font-weight: 600;
  margin: 10px 0 25px;
  color: #202124;
  text-align: center;
}

.contact-block {
  position: relative;
  width: 100%;
  //max-height: 590px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  //padding-top: 25px;
  border-top: 1px solid #ededed;
}

.press-center {
  //width: 90%;
  /* height: 180px; */
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e9e9e9;
  margin: 0 25px 25px 25px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 15px;
}

.main-office {
  width: 452px;
  height: 212px;
  //box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.05);
  margin-left: 44px;
  background-color: #ffffff;
  span {
    @include font-styled;
  }
}

.office-image-address-block {
  margin-top: 15px;
  display: flex;
}

.map-point-icon {
  width: 63px;
  text-align: center;
}

.office-address {
  width: 250px;
  @include telephone-styled;
  &:hover {
    //cursor: pointer;
    //text-decoration: underline;
  }
}

.telephone-numbers {
  display: flex;
  margin-top: 13px;
  .tel-numbers {
    margin-left: 15px;
    height: 30px;
    @include telephone-styled;
  }
}

.fax-site {
  margin-left: 30px;
  height: 30px;
  @include telephone-styled;
}

.press-contact {
  padding: 18px 0 7px 18px;
  border-bottom: 1px solid #e9e9e9;

  .orgstr-addrr-contacts {
    margin-bottom: 8px;
  }
}

.orgstr-addrr-header {
  margin-bottom: 15px;
}

.split-press {
  border: none;
  color: #e9e9e9;
  background-color: #e9e9e9;
  height: 1px;
  margin-bottom: -2px;
  margin-top: 0;
}

.top-border {
  //border-top: 1px solid #ededed;

}

.contact-form-header {
  text-align: center;
  @include font-styled;
}

.feedback-input {
  height: 40px;
  border-radius: 6px;
  border: solid 1px #ededed;
  background-color: #f6f6f6;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 24px;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #202124;

  &::placeholder {
    text-align:right;
  }
}

.feedback-input-error {
  border: solid 1px #ed0b13;
  @extend .feedback-input;
}

.email-depart {
  display: flex;
}

.left{
  padding: 39px 25px 20px 25px;
  span {
    @include input-name;
  }
}

.feedback-textarea {
  height: 135px;
  padding-top: 5px;
  @extend .feedback-input;
}

.feedback-textarea-error {
  border: solid 1px #ed0b13;
  @extend .feedback-textarea;
}

.button-contact-submit {
  width: 200px;
  height: 49px;
  border-radius: 100px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.12);
  background-color: #df6d17;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
}

.sub-but {
  text-align: center;
  margin: 20px;
}

.captcha-block {
  span {
    @include input-name;
  }
  .captcha-inputs {
    display: flex;
  }

  .captcha-input {
    height: 35px;
    @extend .feedback-input;
  }
}

.equal-height {
  display: flex;
  align-items: stretch;
  margin-bottom: 50px;
  padding-bottom: 15px;
}

.department-block {
  position: relative;
}

.depart-wrapper {
  box-shadow: 0 0 30px 0 rgba(0,0,0,.14);
  margin-top: -20px;
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #ffffff;
  li {
    padding: 16px 14px;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
      color: #df6d17;
    }
  }
}

.map-block {
  position: relative;
  margin-top: -23px;
  img {
    max-width: 100%;
    width: 100%;
  }
}

.shift-map {
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: rgba(172, 172, 172, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .shift-map-arrow {
    font-size: 24px;
    color: #ffffff;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(140, 140, 140, 0.8);
  }
}

.vo {
  height: 100%;
  width: 100%;
  iframe {
    height: 619px;
  }
}

.contact-orgstr-addrr-block {
  padding: 0 30px;
  display: block;
}

.mobile-map {
  width: 90%;
  margin: 0 auto 20px;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.press-center-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #202124;
}

@media only screen and (max-width: 1200px) {
  .right {
    height: 60vw;
  }
}

@media only screen and (max-width: 440px) {
  .fold {
    padding: 0 .5em 0 .5em;

    .affiliates-address {
      width: 200px;
      line-height: 1;
    }

    .fold_trigger {
      font-size: 18px;
    }
  }
  .contact-big-name {
    font-size: 28px;
  }
}
