.page-wrapper {
  width: 100%;
}

.state-page {
  .secondary-slider {
    height: 100%;
    padding-bottom: 40px;

    .slick-arrow {
      bottom: -65px;
    }
  }

  .list-item_wr {
    box-shadow: 0 10px 25px 0 rgba(0,0,0,0);
    height: 155px;
    &:hover {
      box-shadow: 0 10px 25px 0 rgba(223,109,23,.3);
      cursor: pointer;
    }
  }

  .slider-big {
    padding-bottom: 75px;
    .warning-item {
      height: 221px;
      .list-item_image {
        background: transparent;
      }
    }
  }

  .list-item_text {
    font-weight: 600;
    font-size: 14px;
    color: #444342;
  }

  .list-item_image img {
    max-width: 100%;
    width: 100%;
  }

  .warning-item {
    .list-item_image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px 0;
      img {
        max-width: 90px;
        width: 100%;
      }
    }

    .list-item_text {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.4px;
      padding: 10px 0 45px;
      text-align: center;
      margin: 0 auto;
      color: #5e5e5e;
      width: 80%;
    }
  }
}

.page-title {
  font-size: 36px;
  font-weight: 600;
  color: #202124;
  padding: 10px 0;
}

.page-subtitle {
  font-size: 16px;
  padding: 10px 0 15px;
  font-weight: bold;
  * {
    margin-right: 20px;
  }
}

.page-content > p {
  font-size: 16px;
  padding: 15px 0;
}

.secondary-slider {
  height: 250px;
}

.section-wrapper {
  margin-bottom: 50px;
  clear: both;
}

.section-title {
  font-size: 22px;
  margin: 25px 0;
  font-weight: 600;
  color: #202124;
}

.section-subtitle a {
  text-decoration: underline;
  &:hover {
    color: #df6d17;
  }
}

.paper-list {
  box-shadow: 0 12px 24px 0 rgba(0,0,0,.15);
  margin-right: 20px;

  li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.15);
    position: relative;
    font-size: 14px;

    &:last-child {
      border: none;
    }

    a {
      padding: 25px 30px;
      display: block;
    }

    &:hover {
      background-color: rgba(223, 109, 23, .15);
      padding-left: 45px;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 5px solid #df6d17;
        left: 30px;
      }
    }
  }
}

@media all and (max-width: 1080px) {
 .state-page {
   .chart-item {
     width: 100%;
   }

   .charts-wrapper {
     padding-right: 0;
   }

   .secondary-slider {
     height: 285px;
     margin-left: 0;
     padding-left: 0;
   }
 }

  .paper-list {
    margin-right: 0;
  }

  .page-content > p {
    font-size: 14px;
    padding: 10px 0;
  }
}

.charts-period-wrapper {
  min-width: 245px;
  margin-right: 10px;
  ul, li {
    display: inline-block;
    margin: 0 2px;
  }

  @media all and (max-width: 768px) {
    padding-top: 15px;
  }

  li {
    padding: 2px 5px;
    cursor: pointer;

    &.active {
      background: #df6d17;
      color: #fff;
    }
  }
}
