.charts-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px;
}

.chart-item {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .15);
  width: calc(50% - 12.5px);
  margin-bottom: 25px;
  font-size: 14px;
  padding: 25px;
}

.chart-item_title {
  font-weight: 600;
  color: #333;
}

.chart-item_nav {
  display: flex;
  flex-wrap: wrap;
  li {
    background: #f6f6f6;
    padding: 5px 20px;
    margin: 0 5px 5px 0;
    &:hover {
      background: #df6d17;
      cursor: pointer;
      color: #fff;
    }
  }

  .active {
    background: #df6d17;
    color: #fff;
  }
}

.chart-item_area {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .08);
  margin-top: 25px;
  font-size: 12px;
}