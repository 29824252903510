@mixin not-last-child($selector) {
  & #{$selector}:not(:last-child) {
    @content;
  }
}

.mobile-header-right {
  display: flex;
  justify-content: space-between;
}

.mobile-logo-level {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-open-close-image {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
}

.mobile-search-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 245px;
  margin: 5px 0;
  width: 70%;
}

.mobile-search-block button {
  background: url('../svg/search.svg') 50% no-repeat #acacac;
  background-size: 60%;
  border-radius: 3px;
  height: 24px;
  width: 25px;
}

.mobile-search-block input {
  background: #f4f4f4;
  border-radius: 3px 0 0 3px;
  padding: 3px 10px;
  font-size: 14px;
}

.mobile-nav-block {
  width: 100%;
  text-align: right;
  background: #f4f4f4;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  left: 0;
  .mobile-nav-bottom {
    display: flex;
    box-shadow: 0 2px 25px 0 rgba(0,0,0,.3);
    justify-content: center;
    align-items: center;
    background: #fff;
    position: fixed;
    height: 55px;
    width: 100%;
    bottom: 0;
  }
}

.mobile-main-nav {
  overflow: auto;
  @include not-last-child(".mob-nav-itm") {
    border-bottom: .3px solid #dedede;
  }
  .mob-nav-itm {
    padding: 12px 15px 12px 0;
    a {
      font-size: 16px;
      text-align: right;
      color: #444342;
    }
  }

  .mob-sub-nav-itm {
    padding: 6px 0 6px 0;
  }

  .mob-sub-nav-itm a {
    font-size: 12px;
    line-height: 1.5;
    text-align: right;
    color: #444342;
  }
}

.opened-nav {
  position: fixed;
  z-index: 1001;
  background: #fff;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 10px 15px 0 15px;
}

.sub-item-rotate {
  transform: rotate(-90deg);
  margin-right: 10px;
}


