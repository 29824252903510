$arrow-left: calc(50% - 570px);
$arrow-width: 50px;

.slick-track {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.editions-page {
  .slick-track {
    margin-left: 0;
  }
}

.slider-carousel {
  .slick-slider {
    background: #ddd;
    overflow: hidden;

    .slick-list,
    .slick-track {
      height: 445px;
      //height: 62vh;
    }
  }

  .slick-slide {
    display: flex;
  }

  .slick-slide > div {
    text-align: center;
    display: flex;
    width: 100%;
  }

  .item-wrapper {
    position: relative;
    display: inline-flex !important;
    align-items: center;
  }

  .slick-slide a,
  .slick-slide img {
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
  }

  .slide-title_wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    max-width: 1440px;
    bottom: 135px;
    width: 100%;
  }

  .slick-current .slide-title_bg {
    transition: opacity .3s ease-in .3s;
    opacity: .8;
  }

  .slide-title_bg {
    background: linear-gradient(99deg, #fff, rgba(255, 255, 255, 0));
    will-change: opacity;
    position: absolute;
    height: 100%;
    width: 80%;
    opacity: 0;
    bottom: 0;
    top: 0;
  }

  .slide-title_text,
  .slide-title_subtext {
    position: relative;
    font-weight: bold;
    line-height: 1.39;
    text-align: left;
    font-size: 36px;
    color: #fff;
    width: 65%;
  }

  .slide-title_subtext {
    font-size: 18px;
  }

  .slick-arrow {
    background: url('../svg/arrow.svg') 50% no-repeat;
    background-size: 8px;
    width: $arrow-width;
    position: absolute;
    font-size: 0;
    height: 50px;
    bottom: 45px;
    z-index: 10;
    &:hover {
      background: url('../svg/arrow-hover.svg') 50% no-repeat;
      background-size: 8px;
    }
  }

  .slick-prev {
    margin-left: 15px;
    left: $arrow-left;
    &:hover {
      &:after {
        content: '';
        border-radius: 0 50px 50px 0;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        border-top: 1px solid #fff;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 50px 0 0 50px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
      }
    }
  }

  .slick-next {
    transform: rotate(180deg);
    margin-left: 115px;
    left: $arrow-left;
    &:hover {
      &:after {
        content: '';
        border-radius: 0 50px 50px 0;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        border-top: 1px solid #fff;
        position: absolute;
        height: calc(100% - 2px);
        border-left: none;
        width: 50%;
        right: 0;
        top: 1px;
      }
      &:before {
        border-radius: 50px 0 0 50px;
        border-bottom: 3px solid #df6d17;
        border-left: 3px solid #df6d17;
        border-top: 3px solid #df6d17;
        width: 50%;
      }
    }
  }

  .slick-prev:after,
  .slick-next:after {
    content: '';
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    top: 1px;
    right: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }


  .slick-dots {
    display: flex !important;
    position: absolute;
    justify-content: center;
    flex-direction: column;
    right: $arrow-left;
    margin-right: 15px;
    bottom: 0;
    top: 0;

    li {
      position: relative;
      margin: 5px 0;
      font-size: 0;
      height: 15px;
      width: 15px;
      &:hover {
        border: 1px solid #df6d17;
        border-radius: 50%;
        cursor: pointer;
      }
      &:after {
        content: '';
        background: rgba(255, 255, 255, .8);
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }

      button {
        position: relative;
        z-index: 10;
        height: 100%;
        width: 100%;
      }
    }

    .slick-active:after {
      background: #df6d17;
    }
  }
}

.slider-carousel {
  max-width: 1440px;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  width: 100%;
}

.slider-gradient {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.45) 68%, rgba(0, 0, 0, 0));
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

@media all and (max-width: 1200px) {
  .slider-carousel .slick-next {
    left: 5%;
  }

  .slider-carousel .slick-prev {
    left: 5%;
  }
}

@media all and (max-width: 1080px) {
  .slider-carousel .slide-title_bg {
    width: 100%;
  }

  .slider-carousel .slick-dots {
    right: 5%;
  }

  .slider-carousel.main-slider .slide-title_text,
  .slider-carousel.main-slider .slide-title_subtext {
    line-height: 1.4;
    font-size: 24px;
  }

  .slider-carousel .slick-next {
    margin-left: 85px;
    left: 10%;
  }

  .slider-carousel .slick-prev {
    left: 10%;
  }
}

@media all and (max-width: 768px) {
  .slider-carousel.main-slider .slide-title_text,
  .slider-carousel.main-slider .slide-title_subtext {
    line-height: 1.2;
    font-size: 24px;
  }

  .slider-carousel .slick-next {
    margin-left: 85px;
    left: 10%;
  }

  .slider-carousel .slick-prev {
    left: 10%;
  }
}

@media all and (max-width: 576px) {
  .slider-carousel .slick-next {
    left: 15px;
  }

  .slider-carousel .slick-prev {
    left: 15px;
  }
}
